import React, { useState } from "react";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { sendRemoveCoachRequestToSupabase } from "../../api/coaches";

interface RemoveAthleteDialogProps {
  removeConfirmationVisible: boolean;
  setRemoveConfirmationVisible: (arg0: boolean) => any;
  coachId: string;
  athleteId: string;
  setIsRemoved: (arg0: boolean) => any;
  athleteView?: boolean
}

const RemoveAthleteDialog: React.FC<RemoveAthleteDialogProps> = ({
  removeConfirmationVisible,
  setRemoveConfirmationVisible,
  coachId,
  athleteId,
  setIsRemoved,
  athleteView=false,
}) => {
  // const showRemoveConfirmation = () => setRemoveConfirmationVisible(true);
  const hideRemoveConfirmation = () => setRemoveConfirmationVisible(false);

  const cancelRemove = () => {
    // Add any additional logic here
    hideRemoveConfirmation();
  };

  const confirmRemove = async () => {
    // remove the athlete/coach here
    const { data, error } = await sendRemoveCoachRequestToSupabase(
      coachId,
      athleteId
    );
    if (error) {
      console.error("error", error);
    }
    if (data.success) {
      console.log("successfully removed");
      hideRemoveConfirmation();
      setIsRemoved(true);
    }
    // hideRemoveConfirmation();
  };

  return (
    <Portal>
      <Dialog
        visible={removeConfirmationVisible}
        onDismiss={hideRemoveConfirmation}
      >
        <Dialog.Title>Confirmation</Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            {
              athleteView
              ? "Are you sure you want to remove this coach?" 
              : "Are you sure you want to remove this athlete from your roster?"
            }
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancelRemove}>Cancel</Button>
          <Button onPress={confirmRemove}>Remove</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default RemoveAthleteDialog;
