import { Platform } from "react-native";

export const MORE_ICON =
  Platform.OS === "ios" ? "dots-horizontal" : "dots-vertical";

export const MERGE_ICON = "vector-union";
export const UNMERGE_ICON = "vector-combine";
export const DUPLICATE_ICON = "content-copy";
export const DELETE_ICON = "delete";

export const ICONS = {
  MORE: Platform.OS === "ios" ? "dots-horizontal" : "dots-vertical",
  DUPLICATE: "content-copy",
  EDIT: "pencil",
  DELETE: "delete",
  MERGE: "vector-union",
  UNMERGE: "vector-combine",
  NOTE: "note",
  EVENT: "trophy",
  WORKOUT_LIBRARY: "bookshelf",
  WORKOUT: "pulse",
  CATEGORY: "folder",
};
