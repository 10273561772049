import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TextInput, TextInputProps } from "react-native-paper";

interface DistanceInputProps extends TextInputProps {
  value?: number | null;
  onDistanceChange: (value: number | null) => void;
  unit?: string;
}

const DistanceInput: React.FC<DistanceInputProps> = ({
  value = null,
  unit = "km",
  onDistanceChange,
  ...textInputProps
}) => {
  const [distanceString, setDistanceString] = useState<string | undefined>(
    value?.toString() ?? undefined
  );

  const handleDistanceChange = (stringValue: string | undefined) => {
    // Remove any non-numeric characters except for the decimal point
    stringValue = stringValue?.replace(/[^0-9.]/g, "");
    setDistanceString(stringValue);
    const numericValue = stringValue ? parseFloat(stringValue) : null;
    // setDistance(isNaN(numericValue) ? null : numericValue);
    onDistanceChange(isNaN(numericValue) ? null : numericValue);
  };

  useEffect(() => {
    setDistanceString(value?.toString() ?? undefined);
  }, [value]);

  return (
    <View style={styles.row}>
      <Text variant="bodyLarge">Distance</Text>
      <View style={styles.container}>
        <TextInput
          {...textInputProps}
          label="Distance"
          value={distanceString}
          onChangeText={handleDistanceChange}
          keyboardType="decimal-pad"
          style={styles.input}
        />
        <Text variant="labelLarge" style={styles.unitText}>
          {unit}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    margin: 8,
  },
  unitText: {
    alignSelf: "center",
    marginLeft: 4,
  },
  input: {
    width: 142,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
});

export default DistanceInput;
