import React from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const theme = useTheme();

  // Sample data for the bar chart
  const data = [
    { category: "A", value: 20 },
    { category: "B", value: 30 },
    { category: "C", value: 15 },
    { category: "D", value: 25 },
  ];

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <Text>
          Insights will be become available once more data has been collected.
          Check again in a few weeks.
        </Text>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
