import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Text } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { CoachData, CoachProfile } from "../types/user";
import { fetchMyCoaches, fetchMyCoachProfiles } from "../api/coaches";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import {
  getUserInitials,
  getUserName,
} from "../components/utils/profileService";
import {
  filterPendingCoaches,
  filterAcceptedCoaches,
} from "../components/utils/coaches";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import Loading from "./utils/Loading";

type MyCoachesProps = NativeStackScreenProps<MainStackParamList, "My Coaches">;

export default function MyCoaches({ navigation, route }: MyCoachesProps) {
  const { state, dispatch } = useCalendar();
  const [loading, setLoading] = useState(true);

  const handleCoachPress = (coach: CoachProfile) => {
    const profile = coach;
    navigation.navigate("Coach Details", { profile });
  };

  useEffect(() => {
    // Define an async function
    const fetchCoaches = async () => {
      setLoading(true);

      try {
        // Fetch coach data from Supabase
        const { data, error } = await fetchMyCoachProfiles();
        if (error) {
          console.log("Error fetching coaches: ", error);
        } else {
          // update global state with fetched data
          dispatch({
            type: CalendarActionTypes.FETCH_COACHES_SUCCESS,
            coaches: data,
          }); // Fetching athletes successful
        }
      } catch (error) {
        console.log(error); // Fetching coaches failed
      }
      setLoading(false);
    };

    // Call the async function
    fetchCoaches();
  }, [state.fetchCoachesRequired]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <Text variant="bodyLarge">
          Coaches can view, create, edit and delete workouts from your schedule.
          Coaches can also view your profile, training history and edit your
          training zones.
        </Text>
        <Text style={styles.text}>
          You can have more than one coach. For example, a strength &
          conditioning coach and a sports physiotherapist can also be added to
          manage your training schedule.
        </Text>
        <Text style={styles.text}>
          A coach must send you an invitation from their account in order for
          you to be coached by them.
        </Text>
      </View>
      {
        // if state.profile.coaches.length > 0, render the coaches list
        filterAcceptedCoaches(state.coaches).length +
          filterPendingCoaches(state.coaches).length >
        0 ? (
          <>
            <List.Section>
              <List.Subheader>My Coaches</List.Subheader>
              {filterAcceptedCoaches(state.coaches).length > 0 ? (
                filterAcceptedCoaches(state.coaches).map((coach, index) => (
                  <List.Item
                    key={index}
                    title={getUserName(coach)}
                    description={`${
                      coach.email
                    }\nLast invitation sent: ${formatFriendlyDate(
                      new Date(coach.updated_at).toString()
                    )}`}
                    left={() => (
                      <Avatar.Text
                        label={getUserInitials(coach)}
                        size={40}
                        style={{ marginLeft: 16 }}
                      />
                    )}
                    onPress={() => {
                      // Handle onPress action
                      handleCoachPress(coach);
                    }}
                  />
                ))
              ) : (
                <List.Item title="No coaches yet." />
              )}
            </List.Section>
            <List.Section>
              <List.Subheader>Pending Invitations</List.Subheader>
              {filterPendingCoaches(state.coaches).length > 0 ? (
                filterPendingCoaches(state.coaches).map((coach, index) => (
                  <List.Item
                    key={index}
                    title={getUserName(coach)}
                    description={`${
                      coach.email
                    }\nInvitation sent: ${formatFriendlyDate(
                      new Date(coach.updated_at).toString()
                    )}`}
                    left={() => (
                      <Avatar.Text
                        label={getUserInitials(coach)}
                        size={40}
                        style={{ marginLeft: 16 }}
                      />
                    )}
                    onPress={() => {
                      // Handle onPress action
                      handleCoachPress(coach);
                    }}
                  />
                ))
              ) : (
                <List.Item title="No pending invitations." />
              )}
            </List.Section>
          </>
        ) : (
          <View style={styles.container}>
            <Text variant="bodyLarge" style={styles.emptyStateText}>
              You don't have any pending coaching invitations yet.
            </Text>
          </View>
        )
      }
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  emptyState: {},
  emptyStateText: {},
  container: {
    padding: 16,
  },
  text: {
    marginTop: 16,
  },
});
