import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native'
import { Avatar, Divider, List } from 'react-native-paper';
import ScreenWrapper from "../components/ScreenWrapper";
import Loading from "./utils/Loading";
import WorkoutListItem from '../components/WorkoutLibrary/WorkoutListItem';
import WorkoutCategoryListItem from '../components/WorkoutLibrary/WorkoutCategoryListItem';
import WorkoutLibraryFabGroup from '../components/WorkoutLibrary/WorkoutLibraryFabGroup';
import CreateWorkoutCategoryDialog from '../components/WorkoutLibrary/CreateWorkoutCategoryDialog';
import { useNavigation } from '@react-navigation/native';
import { LibraryWorkoutData, WorkoutLibraryData } from '../types/libraryWorkout';
import { fetchWorkoutLibrary } from '../api/workouts';


const WorkoutLibrary = () => {
  const navigation = useNavigation();
  const [workoutLibrary, setWorkoutLibrary] = useState([]);
  const [isCreateCategoryDialogVisible, setIsCreateCategoryDialogVisible] = useState<bool>(false);
  const [fetchRequired, setFetchRequired] = useState<bool>(true);
  const [loading, setLoading] = useState<bool>(true);

  const handleMenuItemPress = (screenName: string) => {
        console.log(`Navigating to ${screenName}`);
  };

  useEffect(() => {
    const fetchAndSetWorkoutLibrary = async () => {
      try {
        const data = await fetchWorkoutLibrary();
        setWorkoutLibrary(data);
      } catch (error) {
        console.error('Failed to fetch workout library:', error);
      }
    };
    if (fetchRequired) {
      fetchAndSetWorkoutLibrary();
      setFetchRequired(false);
      setLoading(false);
    }
  }, [fetchRequired]);

  if (loading) {
    return <Loading />;
  }

  return (
    
      <View style={{ flex: 1 }}>
      <List.Section>
      {workoutLibrary.filter(category => category.type === "category").map((category) => (
        <WorkoutCategoryListItem
          key={category.id}
          id={category.id}
          title={category.title}
          items={category.items}
        />
      ))}
      {workoutLibrary
        .filter(item => item.type !== "category")
        .map(item => (
          <WorkoutListItem
            key={item.id}
            id={item.id}
            item={item}
            navigation={navigation}
            title={item.title}
            description={item.description}
            activityTypeEnum={item.type}
            />
        ))}  
      </List.Section>
        <WorkoutLibraryFabGroup navigation={navigation} setIsCreateCategoryDialogVisible={setIsCreateCategoryDialogVisible} />
        <CreateWorkoutCategoryDialog
          visible={isCreateCategoryDialogVisible}
          hideDialog={() => setIsCreateCategoryDialogVisible(false)}
          setFetchRequired={setFetchRequired}
        />
          </View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    justifyContent: 'space-between', // This will push the content to the top and bottom
    
  },
});

export default WorkoutLibrary;