import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TextInput, TextInputProps } from "react-native-paper";

interface PaceInputProps extends TextInputProps {
  defaultMinutes?: string;
  defaultSeconds?: string;
  onPaceChange: (totalSeconds: number) => void;
  unit?: string;
}

const PaceInput: React.FC<PaceInputProps> = ({
  defaultMinutes = "",
  defaultSeconds = "",
  unit = "/km",
  onPaceChange,
  ...textInputProps
}) => {
  const [minutes, setMinutes] = useState<string>(defaultMinutes);
  const [seconds, setSeconds] = useState<string>(defaultSeconds);

  useEffect(() => {
    const totalSeconds = calculateTotalSeconds("0", minutes, seconds);
    onPaceChange(totalSeconds);
  }, [minutes, seconds, onPaceChange]);

  const handleMinutesChange = (text: string) => {
    setMinutes(text);
  };

  const handleSecondsChange = (text: string) => {
    setSeconds(text);
  };

  const calculateTotalSeconds = (h: string, m: string, s: string): number => {
    const hours = parseInt(h) || 0;
    const minutes = parseInt(m) || 0;
    const seconds = parseInt(s) || 0;

    return hours * 3600 + minutes * 60 + seconds;
  };

  return (
    <View style={styles.row}>
      <Text variant="bodyLarge">Pace</Text>

      <View style={{ flexDirection: "row", marginBottom: 12 }}>
        <TextInput
          {...textInputProps}
          label="mm"
          value={minutes}
          onChangeText={handleMinutesChange}
          keyboardType="numeric"
          style={styles.input}
        />

        <Text variant="labelLarge" style={styles.separator}>
          :
        </Text>

        <TextInput
          {...textInputProps}
          label="ss"
          value={seconds}
          onChangeText={handleSecondsChange}
          keyboardType="numeric"
          style={styles.input}
        />

        <Text variant="labelLarge" style={styles.unitText}>
          {unit}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    width: 65,
  },
  separator: {
    alignSelf: "center",
    marginHorizontal: 4,
  },
  unitText: {
    alignSelf: "center",
    marginLeft: 4,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // paddingVertical: 8,
    paddingHorizontal: 16,
  },
});

export default PaceInput;
