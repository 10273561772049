import React, { createContext, useState, useContext, useEffect } from "react";
import {
  adaptNavigationTheme,
  Provider as PaperProvider,
} from "react-native-paper";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import appLightTheme from "./appLightTheme";
import appDarkTheme from "./appDarkTheme";
import { Dimensions } from "react-native";

const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
});

export const CombinedDefaultTheme = {
  ...appLightTheme,
  ...LightTheme,
  colors: {
    ...appLightTheme.colors,
    ...LightTheme.colors,
  },
};

export const CombinedDarkTheme = {
  ...appDarkTheme,
  ...DarkTheme,
  colors: {
    ...appDarkTheme.colors,
    ...DarkTheme.colors,
  },
};

const ThemeContext = createContext({
  theme: CombinedDefaultTheme,
  toggleTheme: () => {},
  currentTheme: CombinedDefaultTheme,
});

const windowDimensions = Dimensions.get("window");
const screenDimensions = Dimensions.get("screen");

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const maxFixedViewWidth = 760;

  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
    maxViewWidth: Math.min(maxFixedViewWidth, windowDimensions.width),
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions({
          window,
          screen,
          maxViewWidth: Math.min(maxFixedViewWidth, window.width),
        });
      }
    );
    return () => subscription?.remove();
  });

  const toggleTheme = () => {
    setIsDarkMode((prev) => !prev);
  };

  const combinedTheme = isDarkMode ? CombinedDarkTheme : CombinedDefaultTheme;

  const contextValue = {
    theme: combinedTheme,
    toggleTheme: toggleTheme,
    isDarkMode: isDarkMode, // Include the isDarkMode state in the context value
    dimensions: dimensions,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      <PaperProvider theme={combinedTheme}>{children}</PaperProvider>
    </ThemeContext.Provider>
  );
};

export const getTheme = (isDarkMode: boolean) => {
  return isDarkMode ? CombinedDarkTheme : CombinedDefaultTheme;
};

export default ThemeProvider;
