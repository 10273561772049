import React, { useState } from "react";
import { TouchableOpacity, View, useColorScheme } from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, Button, TextInput } from "react-native-paper";
import AuthLayout, { authStyles } from "./AuthLayout";
import config from "../../../config";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "ForgetPassword">) {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const isDarkmode = useColorScheme() === "dark";

  async function forget() {
    setLoading(true);
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: config.FORGET_PASSWORD_URL,
    });
    console.log(config.FORGET_PASSWORD_URL);
    console.log("reset pass word data", data);
    if (!error) {
      setLoading(false);
      navigation.navigate("Login");
      alert("Check your email to reset your password!");
    }
    if (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  return (
    <AuthLayout pageTitle="Forget Password">
      <Text>Email</Text>
      <TextInput
        style={{ marginTop: 15 }}
        placeholder="Enter your email"
        value={email}
        autoCapitalize="none"
        autoCompleteType="off"
        autoCorrect={false}
        keyboardType="email-address"
        onChangeText={(text) => setEmail(text)}
      />
      <Button
        mode="contained"
        onPress={forget}
        style={{ marginTop: 20 }}
        disabled={loading}
        loading={loading}
      >
        {loading ? "Loading" : "Send email"}
      </Button>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: 14 }}>Already have an account?</Text>
        <TouchableOpacity onPress={() => navigation.navigate("Login")}>
          <Text style={{ fontSize: 14, marginLeft: 5 }}>Login here</Text>
        </TouchableOpacity>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          justifyContent: "center",
        }}
      >
        <TouchableOpacity onPress={() => navigation.navigate("ResetPassword")}>
          <Text style={{ fontSize: 14, marginLeft: 5 }}>
            Reset Password here
          </Text>
        </TouchableOpacity>
      </View>
    </AuthLayout>
  );
}
