import React, { useState } from "react";
import { Button, Dialog, Portal, Text, Provider } from "react-native-paper";

interface SuperButtonProps {
  apiFunction: () => Promise<{ data?: any; error?: any }>; // The API function passed from AdminTools
  buttonText: string; // Button text to display
  onDataReceived: (response: { data?: any; error?: any }) => void; // Callback to pass response back to AdminTools
}

const SuperButton: React.FC<SuperButtonProps> = ({
  apiFunction,
  buttonText,
  onDataReceived,
}) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const handleConfirm = async () => {
    hideModal(); // Close the modal
    const { data, error } = await apiFunction(); // Execute the API function
    console.log("SuperButton data", data);
    console.log("SuperButton error", error);
    onDataReceived({ data, error }); // Pass the data back
  };

  return (
    <Provider>
      <Button icon="api" mode="outlined" onPress={showModal}>
        {buttonText}
      </Button>
      <Portal>
        <Dialog visible={visible} onDismiss={hideModal}>
          <Dialog.Title>Confirm</Dialog.Title>
          <Dialog.Content>
            <Text>Are you sure you want to proceed?</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={handleConfirm}>Yes</Button>
            <Button onPress={hideModal}>No</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Provider>
  );
};

export default SuperButton;
