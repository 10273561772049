import * as React from "react";
import { GestureResponderEvent, View } from "react-native";
import {
  Button,
  Dialog,
  Portal,
  PaperProvider,
  Text,
} from "react-native-paper";
import { sendStravaDeauthRequestToSupabase } from "../../api/strava";
import { useCalendar } from "../../provider/CalendarProvider";

const DisconnectDialog = ({
  visible,
  hideDialog,
}: {
  visible: boolean;
  hideDialog: () => void;
}) => {
  const { state, dispatch } = useCalendar();

  const confirmDisconnectStrava = async () => {
    try {
      await sendStravaDeauthRequestToSupabase();
      dispatch({
        type: "FETCH_PROFILE",
      });
      hideDialog();
    } catch (error) {
      console.error("Error disconnecting from Strava:", error);
      // Handle any errors here
    }
  };

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>Deauthorize Strava access</Dialog.Title>
        <Dialog.Content>
          <Text variant="bodyMedium">
            Area you sure you want to disconnect Strava?
          </Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={hideDialog}>Cancel</Button>
          <Button onPress={confirmDisconnectStrava}>Yes</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default DisconnectDialog;
