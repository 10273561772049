import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useNavigation } from "@react-navigation/native";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import { fetchProfile, updateProfile } from "../api/users";
import { supabase } from "../initSupabase";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import { Database } from "../types/supabase";
import { DatePickerInput } from "react-native-paper-dates";
import { CoachedAthleteProfile, initialUserProfile } from "../types/user";
import ProfileForm from "../components/User/ProfileForm";
import RemoveAthleteDialog from "../components/Coaches/ConfirmRemove";

type MyAthletesProps = NativeStackScreenProps<
  MainStackParamList,
  "My Athlete Details"
>;

const MyAthleteDetails: React.FC<MyAthleteDetailsProps> = ({
  route,
  navigation,
}) => {
  const { profile } = route.params as { profile: CoachedAthleteProfile };
  const { state, dispatch } = useCalendar();
  const [removeConfirmationVisible, setRemoveConfirmationVisible] =
    useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  // create a null function for setProfile
  const setProfile = () => {};
  // const navigation = useNavigation();
  const isCoached = profile.status === "accepted";
  const handleRemoveAthlete = () => {
    // Logic to handle deleting account
    setRemoveConfirmationVisible(true);
  };

  const coachedStatusText = () => {
    if (profile.status === "pending") {
      return `Invitation pending since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else if (profile.status === "accepted") {
      return `Coached by you since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else {
      return `Coached status unknown`;
    }
  };

  useEffect(() => {
    if (isRemoved) {
      dispatch({ type: CalendarActionTypes.FETCH_ATHLETES }); // Dispatch FETCH_ATHLETES when an athlete is removed
      navigation.goBack();
    }
  }, [isRemoved]);

  return (
    <>
      <ScreenWrapper withForms={true}>
        <View>
          <List.Section>
            <List.Subheader>Athlete Coached Status</List.Subheader>
            <List.Item
              title={coachedStatusText()}
              onPress={handleRemoveAthlete}
              titleStyle={isCoached ? { color: "green" } : { color: "orange" }}
            />
          </List.Section>
        </View>
        {isCoached ? (
          <ProfileForm
            profile={profile}
            setProfile={setProfile}
            disabled={true}
          />
        ) : (
          <List.Section>
            <List.Subheader>Profile</List.Subheader>
            <TextInput
              label="Email"
              value={profile.email}
              editable={false}
              disabled={true}
              mode="outlined"
              style={styles.input}
            />
          </List.Section>
        )}
        <View>
          <List.Section>
            <List.Item
              title={
                isCoached ? "Remove Athlete from Roster" : "Cancel Invitation"
              }
              onPress={handleRemoveAthlete}
              titleStyle={{ color: "red" }}
              style={styles.input}
            />
          </List.Section>
        </View>
      </ScreenWrapper>
      <RemoveAthleteDialog
        removeConfirmationVisible={removeConfirmationVisible}
        setRemoveConfirmationVisible={setRemoveConfirmationVisible}
        coachId={profile.coach_id}
        athleteId={profile.athlete_id}
        setIsRemoved={setIsRemoved}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 16,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});

export default MyAthleteDetails;
