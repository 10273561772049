// activityService.ts
import { supabase } from "../initSupabase";
import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  subDays,
  addDays,
} from "date-fns";
import { getPagination } from "./utils";

interface QueryOptions {
  page?: number;
  before?: Date;
  after?: Date;
  pageSize?: number;
}

export async function fetchActivityRecordingByWorkoutId(workoutId: string) {
  try {
    const { data, error } = await supabase
      .from("activity_recordings")
      .select("*")
      .eq("workout_id", workoutId);

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error fetching activity recording by workout id:", error);
    throw error;
  }
}

export async function fetchActivityRecordings({
  query: { page = 0, before, after, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase
      .from("activity_recordings")
      .select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    if (before) {
      supabaseQuery = supabaseQuery.lt("started_at", before.toISOString());
    }
    if (after) {
      supabaseQuery = supabaseQuery.gte("started_at", after.toISOString());
    }
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("started_at", { ascending: false });
    // Fetch activities and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function deleteActivityRecording(id: string) {
  try {
    // now delete the activity recording
    const { error } = await supabase
      .from("activity_recordings")
      .delete()
      .eq("id", id);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error deleting activity recording:", error);
    throw error;
  }
}

export async function purgeActivityRecording(id: string) {
  try {
    // now delete the activity recording
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    const { data, error } = await supabase.functions.invoke(
      "process-activity-recordings",
      {
        body: {
          action: "purge",
          user_id: user_id,
          id: id,
        },
      }
    );
    console.log(data);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error deleting activity recording:", error);
    throw error;
  }
}

export async function mergeActivityRecordingsAndWorkouts(
  workoutIds: string[],
  activityRecordingIds: string[]
) {
  try {
    // now delete the activity recording
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    const { data, error } = await supabase.functions.invoke(
      "process-activity-recordings",
      {
        body: {
          action: "merge",
          user_id: user_id,
          workoutIds: workoutIds,
          activityRecordingIds: activityRecordingIds,
        },
      }
    );
    console.log(data);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error deleting activity recording:", error);
    throw error;
  }
}

export async function fetchActivityRecordingsByTypeAndDate(
  date: string | null,
  type: ActivityTypeValue | null
) {
  try {
    console.log("fetchWorkoutsByTypeAndDate", date, typeof date, type);
    const { data, error } = await supabase
      .from("activity_recordings")
      .select("*")
      .eq("type", type)
      .eq("workout_date", date);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching workouts by type and date:", error);
    throw error;
  }
}
