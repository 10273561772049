import React, { createRef, useEffect, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  NavigationContainerRef,
  useNavigation,
} from "@react-navigation/native";
import CustomAppBar, { CustomAppBarProps } from "./CustomAppBar";
import Settings from "../screens/Settings";
import AccountSettings from "../screens/AccountSettings";
import MainTabs from "./MainTabs";
import About from "../screens/About";
import EditActivity from "../screens/EditActivity";
import ActivityDetails from "../screens/ActivityDetails";
import WorkoutLibrary from "../screens/WorkoutLibrary";
import ResetPassword from "../screens/auth/ResetPassword";
import MyCoaches from "../screens/MyCoaches";
import MyCoachDetails from "../screens/MyCoachDetails";
import MyAthletes from "../screens/MyAthletes";
import MyAthleteDetails from "../screens/MyAthleteDetails";
import * as Linking from "expo-linking";
import { useAuth } from "../provider/AuthProvider";
import ConnectedApps from "../screens/ConnectedApps";
import ConnectStrava from "../screens/ConnectStrava";
import { sendStravaAuthCodeToSupabase } from "../api/strava";
import MergeWorkouts from "../screens/MergeWorkouts";
import EditLibraryWorkout from "../screens/EditLibraryWorkout";
import LibraryWorkoutDetails from "../screens/LibraryWorkoutDetails";
import { ROUTES } from "../types/navigation";
import AdminTools from "../screens/AdminTools";

const MainStack = createNativeStackNavigator();

// GitLab pages used for RN web build doesnt handle routes, causing 404s. Have fixed by
// using a # in the callback url, eg to /#api/auth/callback/strava
// this function removes the # with so the url and query params can be parsed
const parseHashedUrlToRoute = (url: string) => {
  let parsedUrl = url;
  if (url.includes("#")) {
    parsedUrl = url.replace("#", "");
  }

  return parsedUrl;
};

const Main = () => {
  const navigation = useNavigation();
  const renderHeader = (props: CustomAppBarProps) => {
    const viewItemBar = props.route.name === "Activity";
    const showMore = props.route.name === "Activity"; // Set showMore to true only for the "ActivityDetails" screen

    return (
      <CustomAppBar {...props} viewItemBar={viewItemBar} showMore={showMore} />
    );
  };
  const url = Linking.useURL();
  const { doResetPassword } = useAuth();
  const [stravaCallbackProcessed, setStravaCallbackProcessed] = useState(false);

  useEffect(() => {
    if (doResetPassword) {
      navigation.navigate("ResetPassword");
    }
  }, []);

  const handleStravaCallback = (url: string) => {
    const { queryParams } = Linking.parse(url);
    // This URL is redirected from Strava OAuth authorization
    // Make an API request to the Supabase backend, providing the code for token exchange and any other necessary data
    if (queryParams?.code && queryParams?.scope) {
      // && !stravaCallbackProcessed)
      sendStravaAuthCodeToSupabase(queryParams.code, queryParams.scope)
        .then((result) => {
          // once authorised, remove the /api/auth/callback/strava url and query parameters
          setStravaCallbackProcessed(true); // Mark the Strava callback as processed
          const newUrl = Linking.createURL("/");
          Linking.openURL(newUrl);
          alert("Success! Strava connected.");
        })
        .catch((error) => {
          // once authorisation attempt fails, remove the /api/auth/callback/strava url and query parameters
          setStravaCallbackProcessed(true); // Mark the Strava callback as processed
          const newUrl = Linking.createURL("/");
          Linking.openURL(newUrl);
          alert("Error during Strava authorization. Please try again.");
          console.error("Error during Strava authorization:", error);
        });
    } else {
      // once authorisation attempt fails, remove the /api/auth/callback/strava url and query parameters
      setStravaCallbackProcessed(true); // Mark the Strava callback as processed
      const newUrl = Linking.createURL("/");
      Linking.openURL(newUrl);
    }
  };

  const handleURL = (url: string) => {
    const parsedUrl = parseHashedUrlToRoute(url);
    // handle redirect from Strava OAuth here
    if (parsedUrl.includes("api/auth/callback/strava")) {
      handleStravaCallback(parsedUrl);
    }
  };

  useEffect(() => {
    // Do something with URL
    if (url) {
      handleURL(url);
    }
  }, [url]);

  return (
    <MainStack.Navigator
      initialRouteName="Chiron"
      screenOptions={({ route }) => ({
        // ts-ignore
        header: renderHeader,
        headerShown: route.name !== "ResetPassword", // Show header for all screens except "ResetPassword"
      })}
    >
      <MainStack.Screen name="Chiron" component={MainTabs} />
      <MainStack.Screen name="About" component={About} />
      <MainStack.Screen name="Settings" component={Settings} />
      <MainStack.Screen name="Account Settings" component={AccountSettings} />
      <MainStack.Screen name="Edit Activity" component={EditActivity} />
      <MainStack.Screen name="Merge Workouts" component={MergeWorkouts} />
      <MainStack.Screen name="Activity" component={ActivityDetails} />
      <MainStack.Screen name="ResetPassword" component={ResetPassword} />
      <MainStack.Screen name="Connected Apps" component={ConnectedApps} />
      <MainStack.Screen name="Connect with Strava" component={ConnectStrava} />
      <MainStack.Screen name="My Coaches" component={MyCoaches} />
      <MainStack.Screen name="My Athletes" component={MyAthletes} />
      <MainStack.Screen name="Athlete Details" component={MyAthleteDetails} />
      <MainStack.Screen name="Coach Details" component={MyCoachDetails} />
      <MainStack.Screen name="Workout Library" component={WorkoutLibrary} />
      <MainStack.Screen name="Library Workout" component={LibraryWorkoutDetails} />
      <MainStack.Screen
        name="Edit Library Workout"
        component={EditLibraryWorkout}
      />
      <MainStack.Screen name={ROUTES.ADMIN_TOOLS} component={AdminTools} />
    </MainStack.Navigator>
  );
};

export default Main;
