// config/index.ts
import { ENV } from "@env";
// Import the common configuration
import commonConfig from "./config.common";

// Determine the environment during runtime
const environment = ENV || "development";

// Import the appropriate environment-specific configuration
let config;

switch (environment) {
  case "production":
    config = require("./config.production").default;
    break;
  case "testing":
    config = require("./config.testing").default;
    break;
  default:
    config = require("./config.development").default;
}

// Merge the common configuration with the environment-specific configuration
const mergedConfig = { ...commonConfig, ...config, environment };

export default mergedConfig;
