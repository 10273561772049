import React, { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "../initSupabase";
import { Session, User } from "@supabase/supabase-js";
import * as Linking from "expo-linking";

type ContextProps = {
  user: null | boolean;
  session: Session | null;
  doResetPassword: boolean;
  setDoResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
  loginWithToken: (tokens: Tokens) => void;
};

const AuthContext = createContext<Partial<ContextProps>>({});

interface Props {
  children: React.ReactNode;
}

const AuthProvider = (props: Props) => {
  // user null = loading
  const [user, setUser] = useState<null | User>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [doResetPassword, setDoResetPassword] = useState<boolean>(false);

  const loginWithToken = async ({ access_token, refresh_token }: Tokens) => {
    const signIn = async () => {
      await supabase.auth.setSession({
        access_token,
        refresh_token,
      });

      return await supabase.auth.refreshSession();
    };

    const {
      data: { user: supabaseUser },
    } = await signIn();
    setUser(supabaseUser);
    const newUrl = Linking.createURL("/");
    Linking.openURL(newUrl);
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data, error }) => {
      setSession(data.session);
      setUser(session ? true : false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        setUser(session ? true : false);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        session,
        loginWithToken,
        doResetPassword,
        setDoResetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("context must be used within an AuthProvider");
  }

  return context;
};
