import React from "react";
import { Button, List, useTheme, Text } from "react-native-paper";
import * as Linking from "expo-linking";
import { STRAVA_REDIRECT_URI, STRAVA_CLIENT_ID } from "@env";
import { STRAVA_AUTHORIZATION_URL } from "../../constants/urls";
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

const CompatibleWithStravaLogo = () => {
  const { colors } = useTheme();
  const navigation = useNavigation();

  const handlePress = () => {
    const screenName = "Connect with Strava"
    navigation.navigate(screenName);
  };

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={handlePress}>
        <Image
          style={{ height: 48, width: 393 }}
          source={require("../../../assets/images/api_logo_cptblWith_strava_horiz_light.png")}
          alt="Compatible with Strava"
        />
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CompatibleWithStravaLogo;
