import React, { useState } from "react";
import { Banner } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";

export default function PendingCoachInvitationBanner() {
  const [visible, setVisible] = useState(true);
  const navigation = useNavigation();

  const handleReviewPress = () => {
    // setVisible(false);
    navigation.navigate("My Coaches");
  };

  return (
    <Banner
      visible={visible}
      icon="account-supervisor"
      actions={[
        {
          label: "REVIEW",
          onPress: handleReviewPress,
        },
      ]}
    >
      You have a pending invitation from a coach.
    </Banner>
  );
}
