// activityUtils.ts

import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  addDays,
  format,
} from "date-fns";
import { types } from "../../constants/activityTypes"; // Import the types constant
import {
  ActivityData,
  ActivityTotals,
  ActivityTotalsPerActivity,
  ActivityTotalsPerDay,
  ActivityTypeValue,
  TotalTypeKey,
  TotalTypeMap,
} from "../../types/activity"; // Import the ActivityData and ActivityTotals types

// Function to filter activities by week
function filterActivitiesByWeek(
  activities: ActivityData[],
  date: Date,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined = 1
) {
  const filteredActivities = activities.filter((activity) => {
    const activityDate = activity.workout_date
      ? new Date(activity.workout_date)
      : new Date();
    return isSameWeek(activityDate, date, { weekStartsOn });
  });

  return filteredActivities;
}

// Function to filter activities by type
function filterActivitiesByType(
  activities: ActivityData[],
  activityType: ActivityTypeValue | null
) {
  if (activityType === null) {
    // If no specific type is provided, return all activities
    return activities;
  }

  return activities.filter((activity) => activity.type === activityType);
}

// Function to check if an activity has been missed
export const hasActivityBeenMissed = (
  isCompleted: boolean | null,
  startedAt: string | Date | number | null
) => {
  const activityDate = startedAt ? new Date(startedAt) : null;
  const today = new Date();
  // Check if the activity is not completed and its start date is before today
  return !isCompleted && activityDate && activityDate < new Date(today.setHours(0, 0, 0, 0));
};

/**
 * Returns a ActivityTotals object of an ActivityData array for the week of the given startDate by the activity 'started_at' value.
 *
 * @param {ActivityData[]} activities - An array of activities.
 * @param {Date} startDate - The date to schedule for activities within the week of.
 * @param {ActivityTypeValue | null} activityType - Optional filter by activityType of null for everything.
 * @returns {ActivityTotals} - The ActivityTotals object for the given parameters.
 * @throws {ErrorType} - Description of when and why this function throws an error.
 *
 * @example
 * // Example usage of the function:
 * const totals = calculateDailyTotals(activities, startDate, activityType);
 */
export function calculateWeeklyTotals(
  activities: ActivityData[],
  startDate: Date = new Date(),
  activityType: ActivityTypeValue | null = null
) {
  // Initialize an ActivityTotals object with all values set to zero
  const initialTotals: ActivityTotals = {
    total_time_planned: 0,
    total_time: 0,
    total_distance: 0,
    total_distance_planned: 0,
    total_count_planned: 0,
    total_count: 0,
    total_time_missed: 0,
    total_distance_missed: 0,
    total_count_missed: 0,
    total_distance_planned_incomplete: 0,
    total_count_planned_incomplete: 0,
    total_time_planned_incomplete: 0,
  };

  // Check if activities is null or undefined
  if (!activities) {
    return initialTotals;
  }

  // Clone the initialTotals object to avoid mutating it
  const totals: ActivityTotals = { ...initialTotals };

  // Filter activities by week
  const filteredByWeek = filterActivitiesByWeek(activities, startDate);

  // Filter activities by type
  const filteredByWeekAndType = filterActivitiesByType(
    filteredByWeek,
    activityType
  );

  filteredByWeekAndType.forEach((activity) => {
    // Check if the activity type matches or if no type is specified
    totals.total_count_planned++;
    totals.total_time_planned += activity.total_time_planned || 0;
    totals.total_distance_planned += activity.total_distance_planned || 0;

    if (activity.is_completed) {
      totals.total_count++;
      totals.total_time += activity.total_time || 0;
      totals.total_distance += activity.total_distance || 0;
    } else if (
      hasActivityBeenMissed(activity.is_completed, activity.started_at)
    ) {
      // Check if the activity has been missed using hasActivityBeenMissed function
      totals.total_count_missed++;
      totals.total_time_missed += activity.total_time_planned || 0; // You can adjust this based on your logic
      totals.total_distance_missed += activity.total_distance_planned || 0; // You can adjust this based on your logic
    }
  });

  return totals;
}

/**
 * Returns a ActivityTotalsPerActivity object of an ActivityData array for the week of the given startDate by the activity 'started_at' value.
 *
 * @param {ActivityData[]} activities - An array of activities.
 * @param {Date} startDate - The date to schedule for activities within the week of.
 * @returns {ActivityTotalsPerActivity} - The ActivityTotalsPerActivity object for the given parameters.
 * @throws {ErrorType} - Description of when and why this function throws an error.
 *
 * @example
 * // Example usage of the function:
 * const totals = calculateDailyTotalsPerActivity(activities, startDate);
 */
export function calculateWeeklyTotalsPerActivity(
  activities: ActivityData[],
  startDate: Date = new Date()
) {
  const weeklyTotals: ActivityTotalsPerActivity = { total: {} };

  // Calculate totals for all activity types
  weeklyTotals.total = calculateWeeklyTotals(activities, startDate);

  // Iterate through each activity type and calculate totals
  const activityTypes: Set<ActivityTypeValue> = new Set();
  activities.forEach((activity) => {
    if (activity.type) {
      activityTypes.add(activity.type);
    }
  });

  // Map activity type values to labels and calculate totals
  activityTypes.forEach((activityType) => {
    const typeLabel =
      types.find((type) => type.value === activityType)?.label || "Unknown";
    const typeTotals = calculateWeeklyTotals(
      activities,
      startDate,
      activityType
    );
    weeklyTotals[typeLabel] = { type: activityType, ...typeTotals };
  });

  return weeklyTotals;
}

/**
 * Returns a ActivityTotals object of an ActivityData array for the day of the given startDate by the activity 'started_at' value.
 *
 * @param {ActivityData[]} activities - An array of activities.
 * @param {Date} startDate - The date to check for activities within the day of.
 * @param {ActivityTypeValue | null} activityType - Optional filter by activityType of null for everything.
 * @returns {ActivityTotals} - The ActivityTotals object for the given parameters.
 * @throws {ErrorType} - Description of when and why this function throws an error.
 *
 * @example
 * // Example usage of the function:
 * const totals = calculateDailyTotals(activities, startDate, activityType);
 */
export function calculateDailyTotals(
  activities: ActivityData[],
  targetDate: Date = new Date(),
  activityType: ActivityTypeValue | null = null
) {
  // Initialize an ActivityTotals object with all values set to zero
  const initialTotals: ActivityTotals = {
    total_time_planned: 0,
    total_time: 0,
    total_distance: 0,
    total_distance_planned: 0,
    total_count_planned: 0,
    total_count: 0,
    total_time_missed: 0,
    total_distance_missed: 0,
    total_count_missed: 0,
    total_distance_planned_incomplete: 0,
    total_count_planned_incomplete: 0,
    total_time_planned_incomplete: 0,
  };

  // Check if activities is null or undefined
  if (!activities) {
    return initialTotals;
  }

  // Clone the initialTotals object to avoid mutating it
  const totals: ActivityTotals = { ...initialTotals };

  // Filter activities by the target date and activity type
  const filteredByDate = activities.filter((activity) => {
    const activityDate = new Date(activity.started_at);
    return (
      activityDate.toDateString() === targetDate.toDateString() &&
      (activityType === null || activity.type === activityType)
    );
  });

  filteredByDate.forEach((activity) => {
    totals.total_count_planned++;
    totals.total_time_planned += activity.total_time_planned || 0;
    totals.total_distance_planned += activity.total_distance_planned || 0;

    if (activity.is_completed) {
      totals.total_count++;
      totals.total_time += activity.total_time || 0;
      totals.total_distance += activity.total_distance || 0;
    } else if (
      hasActivityBeenMissed(activity.is_completed, activity.started_at)
    ) {
      totals.total_count_missed++;
      totals.total_time_missed += activity.total_time_planned || 0;
      totals.total_distance_missed += activity.total_distance_planned || 0;
    } else {
      totals.total_count_planned_incomplete++;
      totals.total_time_planned_incomplete += activity.total_time_planned || 0;
      totals.total_distance_planned_incomplete +=
        activity.total_distance_planned || 0;
    }
  });

  return totals;
}

/**
 * Returns a ActivityTotalsPerActivity object for a given date.
 *
 * @param {ActivityData[]} activities - Description of the parameter.
 * @param {Date} startDate - Description of the parameter.
 * @returns {ActivityTotalsPerActivity} - Description of the return value.
 * @throws {ErrorType} - Description of when and why this function throws an error.
 *
 * @example
 * // Example usage of the function:
 * const totals = calculateDailyTotalsPerActivity(activities, startDate);
 */
export function calculateDailyTotalsPerActivity(
  activities: ActivityData[],
  startDate: Date = new Date()
) {
  const dailyTotals: ActivityTotalsPerActivity = { total: {} };

  // Calculate totals for all activity types
  dailyTotals.total = calculateDailyTotals(activities, startDate);

  // Iterate through each activity type and calculate totals
  const activityTypes: Set<number> = new Set();
  activities.forEach((activity) => {
    if (activity.type) {
      activityTypes.add(activity.type);
    }
  });

  // Map activity type values to labels and calculate totals
  activityTypes.forEach((activityType) => {
    const typeLabel =
      types.find((type) => type.value === activityType)?.label || "Unknown";
    const typeTotals = calculateDailyTotals(
      activities,
      startDate,
      activityType
    );
    dailyTotals[typeLabel] = { type: activityType, ...typeTotals };
  });

  return dailyTotals;
}

// Function to calculate daily totals for a given date
function calculateDailyTotalsForDate(
  activities: ActivityData[],
  date: Date,
  activityType: ActivityTypeValue | null = null
) {
  return calculateDailyTotals(activities, date, activityType);
}

// Function to calculate daily totals for each day of a week
export function calculateWeeklyTotalsPerDay(
  activities: ActivityData[],
  startDate: Date = new Date(),
  activityType: ActivityTypeValue | null = null
) {
  const weeklyTotals: ActivityTotalsPerActivity = {};

  const startDateClone = new Date(startDate);

  // Calculate daily totals for each day of the week
  for (let i = 0; i < 7; i++) {
    const currentDate = addDays(startDateClone, i);
    const currentDateFormatted = format(currentDate, "yyyy-MM-dd");

    // Calculate daily totals for the current date
    const dailyTotals = calculateDailyTotalsForDate(
      activities,
      currentDate,
      activityType
    );

    // Store the daily totals in the object with the date as the key
    weeklyTotals[currentDateFormatted] = dailyTotals;
  }

  return weeklyTotals;
}

/**
 * Calculates and returns weekly activity totals for each activity type, including daily totals
 * for each day of the week, starting from the given target date.
 *
 * @param {ActivityData[]} activities - An array of activities.
 * @param {Date} targetDate - The target date for which weekly totals are calculated.
 * @returns {ActivityTotalsPerActivity} - An object containing weekly activity totals for each activity type
 * with daily totals for each day of the week.
 *
 * @example
 * // Example usage of the function:
 * const weeklyTotals = calculateWeeklyTotalsPerActivityAndDay(activities, startDate);
 * // Result:
 * // {
 * //   "Run": {
 * //     "total_count": 3,
 * //     "total_count_missed": 1,
 * //     "total_count_planned": 4,
 * //     "total_distance": 12.5,
 * //     "total_distance_missed": 3.2,
 * //     "total_distance_planned": 15.7,
 * //     "total_time": 10800,
 * //     "total_time_missed": 2700,
 * //     "total_time_planned": 13500,
 * //     "type": 1,
 * //     "daily": {
 * //       "2023-09-25": { "total_count": 1, "total_time": 3600, ... },
 * //       "2023-09-26": { "total_count": 0, "total_time": 0, ... },
 * //       ...
 * //     }
 * //   },
 * //   "Swim": { ... },
 * //   ...
 * // }
 */
export function calculateWeeklyAndDailyTotalsPerActivity(
  activities: ActivityData[],
  targetDate: Date = new Date()
) {
  const startOfWeekDate = startOfWeek(targetDate, { weekStartsOn: 1 });

  const weeklyTotalsPerActivity: ActivityTotalsPerActivity = {};

  // Calculate weekly totals for all activity types and sort by activity type
  const weeklyTotals = calculateWeeklyTotalsPerActivity(
    activities,
    startOfWeekDate
  );

  Object.entries(weeklyTotals).forEach(
    ([activityTypeLabel, activityTypeTotals]) => {
      const dailyTotals = calculateWeeklyTotalsPerDay(
        activities,
        startOfWeekDate,
        activityTypeTotals.type
      );

      // Insert the daily totals into the weekly totals for each activity type
      weeklyTotalsPerActivity[activityTypeLabel] = {
        ...activityTypeTotals,
        daily: dailyTotals,
      };
    }
  );

  return weeklyTotalsPerActivity;
}

/**
 * Converts the daily totals object into an array of objects with a "date" property added to each.
 *
 * @param {ActivityTotalsPerDay} dailyTotals - The daily totals object to convert.
 * @returns {Array<ActivityTotalsPerDay>} - An array of objects with a "date" property added to each.
 *
 * @example
 * // Example usage of the function:
 * const dailyTotalsArray = convertDailyTotalsObjectToArray(dailyTotals);
 * // Result:
 * // [
 * //   { date: "2023-09-25", total_count: 1, ... },
 * //   { date: "2023-09-26", total_count: 2, ... },
 * //   ...
 * // ]
 */
export function convertDailyTotalsObjectToArray(
  dailyTotals: ActivityTotalsPerDay
): Array<ActivityTotalsPerDay> {
  const dailyTotalsArray = [];

  // Iterate through the keys (dates)
  for (const date in dailyTotals) {
    if (dailyTotals.hasOwnProperty(date)) {
      const dailyData = dailyTotals[date];
      dailyTotalsArray.push({ date, ...dailyData });
    }
  }

  return dailyTotalsArray;
}

/**
 * Converts a string representing a data field into a standardized 'total' type.
 * This function is useful for mapping user input strings to predefined total types.
 *
 * @param {string} inputString - The input string representing a data type (e.g., "duration", "distance", "count").
 *
 * @returns {string | null} The standardized 'total' type corresponding to the inputString.
 * If the inputString is not found in the mapping, the function returns null.
 */
export function convertStringToTotalType(
  totalType: TotalTypeKey
): string | null {
  // Mapping of inputString to standardized 'total' type
  const typeMap: TotalTypeMap = {
    duration: "total_time",
    distance: "total_distance",
    count: "total_count",
  };

  // Use the inputString to look up the corresponding value in the typeMap
  const result = typeMap[totalType];

  // If the result is undefined, you can handle it as needed (e.g., return a default value)
  // For simplicity, we return null if the inputString is not found in the map.
  return result || null;
}

// total_count_missed total_count_planned

// function to return the ActivityTypeLabel from the ActivityTypeValue
export function getActivityTypeLabel(
  activityType: ActivityTypeValue | null
): string {
  const activityTypeLabel =
    types.find((type) => type.value === activityType)?.label || "Unknown";
  return activityTypeLabel;
}
