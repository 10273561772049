import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
// import View from "../components/ThemedView";
import { Card, DataTable, Text } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { types } from "../constants/activityTypes";
import { formatDuration, formatDate } from "../components/utils/time";
import ScreenWrapper from "../components/ScreenWrapper";
import ActivityCardTitle from "../components/Activity/ActivityCardTitle";
import { getActivityStatusColor } from "../components/utils/activityStatusColor";
import { calculateFormatPace, calculateFormatUserSpeed } from "../components/utils/calculatePace";
import { useCalendar } from "../provider/CalendarProvider";
import { fetchActivityById } from "../api/activities";
import { fetchActivityRecordingByWorkoutId } from "../api/activityRecordings";
import { Tables } from "../types/supabase";
import {
  formatDistance,
  metersToUserUnits,
  metersToFormattedUserUnits,
} from "../components/utils/distance";
import { speedOrPaceLabel } from "../components/utils/calculatePace";
import ActivityRecordingCard from "../components/ActivityRecording/ActivityRecordingCard";
import { ActivityData } from "../types/activity";

type ActivityDetailsProps = NativeStackScreenProps<
  MainStackParamList,
  "Activity"
>;

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  route,
  navigation,
}) => {
  const { activity } = route.params as { activity: ActivityData };
  const [updatedActivity, setUpdatedActivity] =
    useState<Tables<"activities">>(activity);
  const [activityRecordings, setActivityRecordings] = useState<
    Tables<"activity_recordings">[]
  >([]);
  const { state, dispatch } = useCalendar();

  const activityStatusColor = getActivityStatusColor(
    updatedActivity.is_completed,
    updatedActivity.started_at
  );

  const formattedDuration = formatDuration(updatedActivity.total_time_planned);
  const formattedStartedAt = formatDate(
    updatedActivity.started_at_local,
    updatedActivity.is_completed
  );

  // Fetch activity when the component mounts
  async function fetchUpdatedActivity(id: string) {
    try {
      const fetchedActivity = await fetchActivityById(id);
      setUpdatedActivity(fetchedActivity);
      const { data, error } = await fetchActivityRecordingByWorkoutId(id);

      setActivityRecordings(data);
      return fetchedActivity;
    } catch (error) {
      // Handle errors here
      console.error("Error fetching activity:", error);
    } finally {
      dispatch({ type: "FETCH_ACTIVITIES_COMPLETE" });
    }
  }

  useEffect(() => {
    if (state.fetchRequired) {
      fetchUpdatedActivity(updatedActivity.id);
    }
  }, [state.fetchRequired]);

  // have added fetch to get activity recordings here which currently needs to be grabbed from server every time
  useEffect(() => {
    fetchUpdatedActivity(updatedActivity.id);
  }, []);

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <ActivityCardTitle
          title={updatedActivity.title}
          activityTypeEnum={updatedActivity.type}
          isCompleted={updatedActivity.is_completed}
          startedAt={updatedActivity.started_at}
          titleVariant="titleLarge"
        />
        <Text
          style={[
            styles.startedAt,
            { color: activityStatusColor }, // Conditionally set text color
          ]}
        >
          {updatedActivity.is_completed ? "Completed" : "Planned"}{" "}
          {formattedStartedAt}
        </Text>
        <Text style={styles.description}>{updatedActivity.description}</Text>
        <Card style={styles.tableContainer}>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title style={styles.centeredCell}>
                Planned
              </DataTable.Title>
              <DataTable.Title style={styles.centeredCell}></DataTable.Title>
              <DataTable.Title style={styles.centeredCell}>
                Completed
              </DataTable.Title>
            </DataTable.Header>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {updatedActivity.total_time_planned !== null
                  ? formatDuration(updatedActivity.total_time_planned)
                  : "-"}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>
                Duration
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {updatedActivity.total_time !== null
                  ? formatDuration(updatedActivity.total_time)
                  : "-"}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
              {metersToFormattedUserUnits(updatedActivity.total_distance_planned,1,updatedActivity.type)}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>
                Distance
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
              {metersToFormattedUserUnits(updatedActivity.total_distance,1,updatedActivity.type)}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
              {calculateFormatUserSpeed(updatedActivity.total_distance_planned, updatedActivity.total_time_planned, updatedActivity.type)}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>{speedOrPaceLabel(updatedActivity.type)}</DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {calculateFormatUserSpeed(updatedActivity.total_distance, updatedActivity.total_time, updatedActivity.type)}
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </Card>
        <View style={styles.heading}>
          <Text variant="titleSmall">Recorded Activities</Text>
        </View>
        {activityRecordings.length > 0 ? (
          activityRecordings.map((activityRecording, index) => (
            <ActivityRecordingCard
              key={index}
              activityRecording={activityRecording}
            />
          ))
        ) : (
          <Text>None.</Text>
        )}
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: 8,
    marginTop: 16,
  },
});

export default ActivityDetails;
