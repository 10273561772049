import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Switch, Text, useTheme } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import StravaConnectButton from "../components/Strava/ConnectButton";
import StravaDisconnectButton from "../components/Strava/DisconnectButton";
import StravaForceRefreshTokenButton from "../components/Strava/TestRefreshButton";
import { fetchProfile } from "../api/users";
import { useCalendar } from "../provider/CalendarProvider";
import StravaConnectionStatusIcon from "../components/Strava/ConnectionStatusIcon";
import Loading from "./utils/Loading";
import { isStravaConnected } from "../components/utils/profileService";
import StravaImportActivitiesButton from "../components/Strava/ImportActivitiesButton";

export default function ConnectStrava() {
  const { state, dispatch } = useCalendar();

  const fetchData = async () => {
    try {
      dispatch({ type: "SET_LOADING" }); // Update the global state
      const data = await fetchProfile(); // Call the utility function

      if (data) {
        // If data is returned, update the global state
        dispatch({
          type: "SET_PROFILE",
          profile: data,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching profile:", error);
    } finally {
      dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  };

  useEffect(() => {
    // Call the function to fetch user profile data when the component mounts
    if (state.fetchProfileRequired) {
      fetchData();
      dispatch({ type: "FETCH_PROFILE_COMPLETE" });
    }
  }, [state.fetchProfileRequired]);

  if (state.loading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      <List.Section>
        <List.Subheader>Strava</List.Subheader>
        <List.Item
          description="Connection status"
          title={
            state.profile?.strava_api_data
              ? state.profile.strava_api_data.connection_status
              : "Unknown"
          }
          right={() => <StravaConnectionStatusIcon />}
        />
        {isStravaConnected(state.profile) ? (
          <>
            <List.Item
              description="Username"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.athlete.username
                  : "Unknown"
              }
            />
            <List.Item
              description="Access token"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.access_token
                  : "Unknown"
              }
            />
            <List.Item
              description="Expiry"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.expires_at
                  : "Unknown"
              }
            />
            <StravaImportActivitiesButton />
            <StravaDisconnectButton />
          </>
        ) : (
          <StravaConnectButton />
        )}
      </List.Section>
    </ScreenWrapper>
  );
}
