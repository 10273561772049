import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import ActivityCard from "./ActivityCard";
import { ActivityData } from "../../types/activity";

const TouchableActivityCard = ({ activity, navigation }) => {
  const handleActivityPress = (
    activity: ActivityData
  ) => {
    navigation.navigate("Activity", { activity });
  };

  return (
    <TouchableOpacity
      style={styles.activityCardContainer}
      onPress={() => handleActivityPress(activity)}
    >
      <ActivityCard
        title={activity.title}
        description={activity.description}
        type={activity.type}
        totalTimePlanned={activity.total_time_planned}
        isCompleted={activity.is_completed}
        startedAt={activity.started_at}
        totalTimeCompleted={activity.total_time}
        totalDistanceCompleted={activity.total_distance}
        totalDistancePlanned={activity.total_distance_planned}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  activityCardContainer: {
    marginBottom: 16,
    width: "100%",
  },
});

export default TouchableActivityCard;
