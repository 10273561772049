import {Platform} from 'react-native';
import { useTheme } from 'react-native-paper'

// console.log('paper theme', theme)

export function getCalendarTheme() {
  const theme = useTheme(); // Get the current theme colors
  const themeColor = theme.colors.primary;
  const lightThemeColor = theme.colors.primaryContainer;
  const disabledColor = theme.colors.onSurfaceDisabled;
  
  return {
    // agenda
    agendaDayTextColor: theme.colors.onSurface,
    agendaDayNumColor: theme.colors.onSurface,
    agendaTodayColor: theme.colors.tertiary,
    agendaKnobColor: theme.colors.outline,
    // arrows
    arrowColor: 'black',
    arrowStyle: {padding: 0},
    // knob
    expandableKnobColor: theme.colors.outline,
    // month
    monthTextColor: theme.colors.onSurface,
    textMonthFontSize: 16,
    textMonthFontFamily: theme.fonts.default.fontFamily,
    // textMonthFontWeight: 'bold' as const,
    // day names
    textSectionTitleColor: theme.colors.onSurface,
    textDayHeaderFontSize: 12,
    textDayHeaderFontFamily: theme.fonts.default.fontFamily,
    textDayHeaderFontWeight: 'normal' as const,
    // dates
    dayTextColor: theme.colors.onSurface,
    todayTextColor: theme.colors.tertiary,
    todayButtonTextColor: themeColor,
    textDayFontSize: theme.fonts.bodyLarge.fontSize,
    textDayFontFamily: theme.fonts.default.fontFamily,
    textDayFontWeight: '500' as const,
    textDayStyle: {marginTop: Platform.OS === 'android' ? 2 : 4},
    // selected date
    selectedDayBackgroundColor: themeColor,
    selectedDayTextColor: 'white',
    // disabled date
    textDisabledColor: disabledColor,
    // dot (marked date)
    dotColor: themeColor,
    selectedDotColor: 'white',
    disabledDotColor: disabledColor,
    dotStyle: {marginTop: -2},
    // background
    calendarBackground: theme.colors.surface,
    reservationsBackgroundColor : theme.colors.surfaceVariant,
    backgroundColor: theme.colors.background,
  };
}