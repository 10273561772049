import React, { createContext, useContext, useReducer } from "react";
import calendarReducer, { CalendarAction } from "./calendarReducer";
import { CalendarState, CoachViewTypes } from "./types";

interface CalendarContextType {
  state: CalendarState;
  dispatch: React.Dispatch<CalendarAction>;
}

const CalendarContext = createContext<CalendarContextType | undefined>(
  undefined
);

export const CalendarProvider: React.FC = ({ children }) => {
  const initialState: CalendarState = {
    showCalendar: true,
    activities: [],
    athletes: [],
    coaches: [],
    schedule: [],
    workoutLibrary: [],
    loading: true,
    focusDate: new Date().toISOString().split("T")[0],
    fetchRequired: true,
    fetchProfileRequired: true,
    fetchAthletesRequired: true,
    fetchCoachesRequired: true,
    fetchWorkoutLibraryRequired: true,
    profile: null,
    error: null,
    coachView: { type: CoachViewTypes.SELF },
  };
  const [state, dispatch] = useReducer(calendarReducer, initialState);

  return (
    <CalendarContext.Provider value={{ state, dispatch }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error("useCalendar must be used within a CalendarProvider");
  }
  return context;
};
