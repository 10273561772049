import React, { useCallback } from "react";
import {
  StyleSheet,
} from "react-native";
import { Avatar, Divider, List } from 'react-native-paper';
import { ActivityTypeValue } from "../../types/activity";


interface ItemProps {
  item: any;
  navigation: any;
  title: string;
  description: string | null;
  activityTypeEnum: ActivityTypeValue
}

const WorkoutListItem = (props: ItemProps) => {
  const { item, navigation, title, description } = props;
 
  const handleMenuItemPress = () => {
    navigation.navigate("Library Workout", { item });
  };

  return (
    <List.Item
        title={title}
        description={description}
        left={props => <List.Icon {...props} icon="run" />}
        onPress={() => handleMenuItemPress()}
    />
  );
};

export default WorkoutListItem;

const styles = StyleSheet.create({
  item: {
    paddingVertical: 4,
    paddingRight: 10,
    flexDirection: "row",
  },
});
