import * as React from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { SegmentedButtons } from "react-native-paper";

const TotalTypeSelect = ({ onSelect }) => {
  const [value, setValue] = React.useState("duration");

  const handleSelect = (value) => {
    setValue(value);

    // Call the parent component's callback function with the selected value
    onSelect(value);
  };

  return (
    <SafeAreaView style={styles.container}>
      <SegmentedButtons
        value={value}
        onValueChange={handleSelect}
        buttons={[
          {
            value: "count",
            label: "Count",
          },
          {
            value: "duration",
            label: "Duration",
          },
          {
            value: "distance",
            label: "Distance",
          },
        ]}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
});

export default TotalTypeSelect;
