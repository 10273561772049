import * as React from "react";
import {
  KeyboardAvoidingView,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  SafeAreaView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { useTheme } from "../provider/ThemeProvider";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type Props = ScrollViewProps & {
  children: React.ReactNode;
  withScrollView?: boolean;
  withForms?: boolean;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

export default function ScreenWrapper({
  children,
  withScrollView = true,
  withForms = false,
  style,
  contentContainerStyle,
  ...rest
}: Props) {
  const { theme, dimensions } = useTheme();

  const insets = useSafeAreaInsets();

  const containerStyle = [
    styles.container,
    {
      backgroundColor: theme.colors.background,
      paddingBottom: insets.bottom,
      paddingLeft: insets.left,
      paddingRight: insets.left,
    },
  ];

  const innerContainerStyle = [
    styles.container,
    {
      backgroundColor: theme.colors.background,

      width: dimensions.maxViewWidth,
      alignSelf: "center",
      padding: 16,
      flex: 1,
    },
  ];

  return (
    <>
      {withScrollView ? (
        <SafeAreaView style={containerStyle}>
          <ScrollView
            {...rest}
            contentContainerStyle={contentContainerStyle}
            alwaysBounceVertical={false}
            showsVerticalScrollIndicator={false}
            style={[innerContainerStyle, style]}
          >
            {withForms ? (
              <FormScreenWrapper>{children}</FormScreenWrapper>
            ) : (
              children
            )}
          </ScrollView>
        </SafeAreaView>
      ) : (
        <View style={[innerContainerStyle, style]}>
          {withForms ? (
            <FormScreenWrapper>{children}</FormScreenWrapper>
          ) : (
            children
          )}
        </View>
      )}
    </>
  );
}

function FormScreenWrapper({ children, ...rest }: Props) {
  if (Platform.OS === "web") {
    return <>{children}</>; // Render children only on the web platform
  }

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View>{children}</View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    
  },
});
