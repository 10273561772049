// user.ts

import { supabase } from "../initSupabase";
import { getUserIdFromSession } from "../components/utils/authUtils";

export async function fetchProfile() {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    // Fetch activities and return the raw data
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", user_id)
      .single();

    if (error) {
      throw error;
    }

    return data; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function fetchUserStravaApiData() {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    // Fetch activities and return the raw data
    const { data, error } = await supabase
      .from("profiles")
      .select("strava_api_data")
      .eq("id", user_id)
      .single();

    if (error) {
      throw error;
    }

    return data; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function updateProfile(userId: string, profile: any) {
  const { data, error } = await supabase
    .from("profiles")
    .update({
      ...profile,
    })
    .eq("id", userId)
    .single();

  if (error) {
    throw error;
  }

  return data;
}

// check if user is superuser
export async function isSuperuser() {
  try {
    const user_id = await getUserIdFromSession();
    if (user_id) {
      const { data, error } = await supabase
        .from("users_meta")
        .select("is_superuser")
        .eq("id", user_id)
        .single();

      if (error) {
        throw error;
      }

      return data?.is_superuser;
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
}
