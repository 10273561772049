import urlPolyfill from "react-native-url-polyfill";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Platform } from "react-native";
import { setupURLPolyfill } from "react-native-url-polyfill";
import { SUPABASE_URL, SUPABASE_ANON_KEY } from "@env";
import { Database } from "./types/supabase";

// "expo-secure-store" is not supported on Web
// const ExpoSecureStoreAdapter = {
//   getItem: (key: string) => {
//     return SecureStore.getItemAsync(key);
//   },
//   setItem: (key: string, value: string) => {
//     SecureStore.setItemAsync(key, value);
//   },
//   removeItem: (key: string) => {
//     SecureStore.deleteItemAsync(key);
//   },
// };

if (Platform.OS !== "web") {
  setupURLPolyfill();
}

const options = {
  auth: {
    // storage: ExpoSecureStoreAdapter as any,
    storage: AsyncStorage as any,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false, // Prevents Supabase from evaluating window.location.href, breaking mobile
  },
};

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  options
) as SupabaseClient<Database>;

// // Load Supabase session from AsyncStorage on app startup
// const loadSupabaseSession = async () => {
//   const sessionData = await AsyncStorage.getItem("supabaseSession");
//   if (sessionData) {
//     const session = JSON.parse(sessionData);
//     supabase.auth.setSession(session);
//   }
// };

// // Save Supabase session to AsyncStorage
// const saveSupabaseSession = async (session: any) => {
//   await AsyncStorage.setItem("supabaseSession", JSON.stringify(session));
// };
