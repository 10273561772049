import React from 'react';
import { View, StyleSheet } from "react-native";
import { Text, Switch } from 'react-native-paper';

// note react-native-paper List.Item with right Switch is not used here, because it seems to be unrresponsive on React Native Web.
// this component is styled to look identical to a ListItem with a Switch on the right.

interface ToggleSwitchProps {
  label: string;
  value: boolean;
  onValueChange: (newValue: boolean) => void;
  textProps?: React.ComponentProps<typeof Text>;
  switchProps?: React.ComponentProps<typeof Switch>;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, value, onValueChange, textProps, switchProps }) => {
  
  return (
    <View style={styles.row}>
      <Text variant="bodyLarge" {...textProps}>{label}</Text>
      <Switch style={styles.switch} value={value} onValueChange={onValueChange} {...switchProps} />
    </View>
  );
};

const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 8,
      paddingHorizontal: 16,
    },
    switch: {
      marginRight: 8,
    }
  });

  export default ToggleSwitch;
