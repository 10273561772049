import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { List, useTheme } from "react-native-paper";
import { Text, TextInput, Button, Divider } from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import Switch from "../components/input/Switch";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import CustomPicker from "../components/input/CustomPicker";
import DurationInput from "../components/input/DurationInput";
import PaceInput from "../components/input/PaceInput";
import { supabase } from "../initSupabase";
import { types } from "../constants/activityTypes";
import DistanceInput from "../components/input/DistanceInput";
import { useCalendar } from "../provider/CalendarProvider";
import {
  userUnitsToMeters,
  metersToUserUnits,
} from "../components/utils/distance";
import { getLocalStartOfDayForDate } from "../components/utils/dateUtils";
import { get } from "lodash";
import { getCurrentViewUserId, getCurrentViewUserProfile } from "../provider/CalendarProvider/formatSchedule";
import { LibraryWorkoutData, emptyLibraryWorkoutData } from "../types/libraryWorkout";
import { createLibraryWorkout, updateLibraryWorkout } from "../api/workouts";

// export const emptyLibraryWorkoutData: LibraryWorkoutData = {
//   type: 1,
//   title: null,
//   description: null,
//   data: null,
//   total_distance_planned: null,
//   total_time_planned: null,
//   average_speed_planned: null,
// };

type EditActivityProps = NativeStackScreenProps<
  MainStackParamList,
  "Edit Library Workout"
> & { isDuplicateMode?: boolean }; // Add isDuplicateMode prop

const EditLibraryWorkout: React.FC<EditActivityProps> = ({ navigation, route }) => {
  const { state, dispatch } = useCalendar();
  // change this in appbar to just pass activity id into route.params and get the activity data from state
  const { libraryWorkout, isDuplicateMode } = route.params;
  // this screen handles create, update library workouts
  const isEditMode = !!libraryWorkout.id;
  // replace activity from route.params with activity from state

  // Initialize libraryWorkoutData with the required properties
  // let libraryWorkoutData: LibraryWorkoutData = emptyLibraryWorkoutData;

  
  const [title, setTitle] = useState("Workout");
  const [description, setDescription] = useState("");
  const [selectedType, setSelectedType] = useState<number | null>(1);
  const [totalTimePlanned, setTotalTimePlanned] = useState<number | null>(
    isEditMode || isDuplicateMode ? libraryWorkoutData.total_time_planned || 0 : 3600
    );
  const [totalDistancePlanned, setTotalDistancePlanned] = useState<
  number | null
  >(null);
  const [avgPacePlanned, setAvgPacePlanned] = useState<number | null>(null);
  const [error, setError] = useState<string>("");
  
  const [libraryWorkoutData, setLibraryWorkoutData] = useState<LibraryWorkoutData>(emptyLibraryWorkoutData);
  
  if (libraryWorkout && libraryWorkout.id) {
    // Assign values to activityData for editing an existing activity
    setLibraryWorkoutData(state.workoutLibrary.find((obj) => obj.id === libraryWorkout.id) || {});
  }

  if (isDuplicateMode && libraryWorkout) {
    // Assign values to activityData for duplicating an activity
    setLibraryWorkoutData(libraryWorkout);
  }

  useEffect(() => {
    if (isEditMode) {
      setTitle(libraryWorkoutData.title || "Workout");
      setDescription(libraryWorkoutData.description || "");
      setSelectedType(libraryWorkoutData.type || 1);
      setTotalTimePlanned(libraryWorkoutData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        libraryWorkoutData.total_distance_planned
          ? metersToUserUnits(libraryWorkoutData.total_distance_planned)
          : null
      );
    }
    if (isDuplicateMode) {
      // only pre-populate library workout data if we are duplicating a library workout
      setTitle(libraryWorkoutData.title || "Workout");
      setDescription(libraryWorkoutData.description || "");
      setSelectedType(libraryWorkoutData.type || 1);
      setTotalTimePlanned(libraryWorkoutData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        libraryWorkoutData.total_distance_planned
          ? metersToUserUnits(libraryWorkoutData.total_distance_planned)
          : null
      );
    }
  }, [libraryWorkoutData]);

  const handleSaveLibraryWorkout = async () => {
    if (!selectedType) {
      setError("Please select a type");
      return;
    }

    try {
      const user_id = getCurrentViewUserId(state) // (await supabase.auth.getSession()).data.session?.user.id;
      const libraryWorkout = ({
        ...libraryWorkoutData,
        title,
        description,
        type: selectedType,
        total_time_planned: totalTimePlanned,
        total_distance_planned: userUnitsToMeters(totalDistancePlanned),
        average_speed_planned: avgPacePlanned,
      });
      console.log('libraryWorkout', libraryWorkout)

      if (isEditMode && libraryWorkoutData.id) {
        // Update existing activity
        const { data, error } = await updateLibraryWorkout(libraryWorkout)

      } else {
        // Create new activity
        console.log('creating...')
        const { data, error } = await createLibraryWorkout(libraryWorkout)
      }
      alert(
        isDuplicateMode
          ? "Workout duplicated successfully!"
          : isEditMode
          ? "Workout updated successfully!"
          : "Workout saved successfully!"
      );
      // set state to fetch activities again
      dispatch({
        type: "FETCH_WORKOUT_LIBRARY",
      });

      navigation.goBack();
    } catch (error) {
      console.error("Error saving workout:", error);
    }
  };

  const handleDurationChange = (totalSeconds: number | null) => {
      setTotalTimePlanned(totalSeconds);
  };

  const handleDistanceChange = (
    totalDistance: number | null,
  ) => {
    setTotalDistancePlanned(totalDistance);
  };

  const handlePaceChange = (totalSeconds: number) => {
      setAvgPacePlanned(totalSeconds);
  };

  return (
    <ScreenWrapper withForms={true}>
      <TextInput
        label="Title"
        value={title}
        onChangeText={(text) => setTitle(text)}
        style={styles.input}
      />
      <CustomPicker
        title="Type"
        options={types}
        selectedValue={selectedType}
        onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
      />
      <TextInput
        label="Description"
        value={description}
        onChangeText={(text) => setDescription(text)}
        multiline={true}
        numberOfLines={6}
        style={{ minHeight : 100 }}
      />
      <Divider />
      <List.Section>
        <DurationInput
          value={totalTimePlanned}
          onDurationChange={(totalSeconds) =>
            handleDurationChange(totalSeconds)
          }
        />
        <DistanceInput
          value={totalDistancePlanned}
          onDistanceChange={(value) => handleDistanceChange(value)}
        />
        <PaceInput
          defaultMinutes=""
          defaultSeconds=""
          onPaceChange={(totalSeconds: number) =>
            handlePaceChange(totalSeconds)
          }
        />
      </List.Section>
      <View style={styles.row}>
        <Button mode="contained" onPress={handleSaveLibraryWorkout}>
          {isDuplicateMode
            ? "Duplicate Workout"
            : isEditMode
            ? "Update Workout"
            : "Add Workout"}
        </Button>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
    // marginBottom: 12,
  },

  subheading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },

  row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
});

export default EditLibraryWorkout;
