import React, { useState } from "react";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { sendAcceptCoachRequestToSupabase } from "../../api/coaches";

interface AcceptCoachDialogProps {
  acceptConfirmationVisible: boolean;
  setAcceptConfirmationVisible: (arg0: boolean) => any;
  coachId: string;
  athleteId: string;
  setIsCoached: (arg0: boolean) => any;
}

const AcceptCoachDialog: React.FC<AcceptCoachDialogProps> = ({
  acceptConfirmationVisible,
  setAcceptConfirmationVisible,
  coachId,
  athleteId,
  setIsCoached,
}) => {
  // const showacceptConfirmation = () => setAcceptConfirmationVisible(true);
  const hideAcceptConfirmation = () => setAcceptConfirmationVisible(false);

  const cancelAccept = () => {
    // Add any additional logic here
    hideAcceptConfirmation();
  };

  const confirmAccept = async () => {
    // Add the logic to accept the athlete here
    const { data, error } = await sendAcceptCoachRequestToSupabase(
      coachId,
      athleteId
    );
    if (error) {
      console.error("error", error);
    }
    if (data.success) {
      setIsCoached(true);
      hideAcceptConfirmation();
    }
    // hideAcceptConfirmation();
  };

  return (
    <Portal>
      <Dialog
        visible={acceptConfirmationVisible}
        onDismiss={hideAcceptConfirmation}
      >
        <Dialog.Title>Confirmation</Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            Are you sure you want to accept this coaches invitation to be coached?
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancelAccept}>Cancel</Button>
          <Button onPress={confirmAccept}>Accept</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default AcceptCoachDialog;
