import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useTheme, Text } from "react-native-paper";
import {
  ExpandableCalendar,
  AgendaList,
  CalendarProvider,
  WeekCalendar,
  Agenda,
  AgendaSchedule,
  DateData,
  AgendaEntry,
} from "react-native-calendars";
import {
  convertActivitiesToAgendaSchedule,
  getCurrentViewUserId,
  getMarkedDates,
} from "../provider/CalendarProvider/formatSchedule";
import AgendaItem from "../components/Activity/AgendaItem";
import Loading from "./utils/Loading";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { MainStackParamList } from "../types/navigation";
import { getCalendarTheme } from "../provider/ThemeProvider/calendarTheme";
import ScheduleFabGroup from "../components/ScheduleFabGroup";
import { useCalendar } from "../provider/CalendarProvider";
import { fetchActivities } from "../api/activities";
import { CalendarContextProps } from "react-native-calendars/src/expandableCalendar/Context";
import Icon from "react-native-vector-icons/MaterialIcons";
import { formatDateWithClientTimeZone } from "../components/utils/dateUtils";

interface Props extends NativeStackScreenProps<MainStackParamList, "MainTabs"> {
  weekView?: boolean;
}

const Schedule = (props: Props) => {
  const { navigation } = props;
  const { state, dispatch } = useCalendar();
  const [agendaSchedule, setAgendaSchedule] = useState({}); // Initialize as an empty object
  const theme = getCalendarTheme();

  const styles = StyleSheet.create({
    calendar: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    header: {
      backgroundColor: theme.backgroundColor,
    },
    section: {
      backgroundColor: theme.backgroundColor,
      color: theme.dayTextColor,
    },
  });

  const renderItem = (item: any) => {
    return <AgendaItem item={item} navigation={navigation} />;
  };

  const onDateChanged = (date: DateData) => {
    dispatch({
      type: "SET_FOCUS_DATE",
      date: date.dateString,
    });
  };

  useEffect(() => {
    const convertedActivities = convertActivitiesToAgendaSchedule(
      state.activities
    );
    setAgendaSchedule(convertedActivities);
  }, [state.activities]);

  // Fetch activities when the component mounts
  async function fetchData() {
    try {
      dispatch({ type: "SET_LOADING" }); // Update the global state
      const selectedViewUserId = getCurrentViewUserId(state);
      const { data } = await fetchActivities({ query: { user_id: selectedViewUserId } }); // Call the utility function

      if (data) {
        // If data is returned, update the global state
        dispatch({
          type: "SET_SCHEDULE",
          activities: data,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching activities:", error);
    } finally {
      dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  }

  useEffect(() => {
    if (state.fetchRequired) {
      fetchData();
      dispatch({ type: "FETCH_ACTIVITIES_COMPLETE" });
    }
  }, [state.fetchRequired]);

  return (
    <View style={{ flex: 1 }}>
      <Agenda
        selected={formatDateWithClientTimeZone(new Date().toISOString())}
        items={agendaSchedule}
        theme={theme}
        style={styles.section}
        showClosingKnob={true}
        firstDay={1}
        // Callback that gets called when items for a certain month should be loaded (month became visible)
        // loadItemsForMonth={(month) => {console.log('trigger items loading', month)}}
        // Callback that fires when the calendar is opened or closed
        // onCalendarToggled={}
        // Callback that gets called on day press
        onDayPress={onDateChanged}
        // Callback that gets called when day changes while scrolling agenda list
        onDayChange={onDateChanged}
        renderItem={renderItem}
        // renderEmptyData={Loading}
        // Specify how each item should be rendered in agenda
        // renderDay={(day, item) => renderDay(day)}
      />
      <ScheduleFabGroup navigation={navigation} />
    </View>
  );
};

export default Schedule;
