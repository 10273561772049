import { Database } from "./supabase";
import { ActivityTypeValue } from "./activity";


type LibraryWorkoutDataBase = Database["public"]["Tables"]["workout_library"]["Row"];
export type LibraryWorkoutData = Partial<LibraryWorkoutDataBase> & { type: ActivityTypeValue }; 

type WorkoutLibraryCategoryDataBase = Database["public"]["Tables"]["workout_library_category"]["Row"];
export type WorkoutLibraryCategory = WorkoutLibraryCategoryDataBase & { items: LibraryWorkoutData[], type: "category" };

// WorkoutLibrary
// [{
//    // LibraryWorkoutCategory
//    id: string;
//    title: string;
//    type: "category";
//    items: LibraryWorkout[];
// },{
//    // LibraryWorkout
//    id: string;
//    title: string;
//    type: ActivityTypeValue;
//    description: string;
//    data: any;
//    total_distance_planned: number;
//    total_time_planned: number;
//    average_speed_planned: number;
// }]
export type WorkoutLibraryData = (LibraryWorkoutCategory | LibraryWorkoutData)[];

export const emptyLibraryWorkoutData: LibraryWorkoutData = {
    type: 1,
    title: null,
    description: null,
    data: null,
    total_distance_planned: null,
    total_time_planned: null,
    average_speed_planned: null,
};