import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Switch, Text, useTheme } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { supabase } from "../initSupabase";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import StravaConnectionStatusIcon from "../components/Strava/ConnectionStatusIcon";
import { UserStravaData } from "../types/user";
import { useNavigation } from "@react-navigation/native";
import CompatibleWithStravaLogo from "../components/Strava/CompatibleWithStravaLogo";

type ConnectedAppsProps = NativeStackScreenProps<MainStackParamList, "Connected Apps">;

export default function ConnectedApps() {
  const { colors } = useTheme();
  const navigation = useNavigation();
  const [userEmail, setUserEmail] = useState<string>("");
  const [userStravaData, setUserStravaData] = useState<null | UserStravaData>(null); // State to hold the users profile

  const handleMenuItemPress = (screenName: keyof MainStackParamList) => {
    navigation.navigate(screenName);
  };

  useEffect(() => {
    // Fetch user data from Supabase
    const fetchUserData = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data.session.user.email) {
        setUserEmail(data.session.user.email);
      } else { setUserEmail("No email found"); }
    };

    fetchUserData();
  }, []);

  return (
    <ScreenWrapper>
      <List.Section>
        <List.Subheader>Applications</List.Subheader>
        <CompatibleWithStravaLogo />
        <List.Item
        title="Strava"
        right={() => <StravaConnectionStatusIcon /> }
        onPress={() => handleMenuItemPress("Connect with Strava")}
      />
      </List.Section>
    </ScreenWrapper>
  );
}

