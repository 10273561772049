import { MD3DarkTheme } from "react-native-paper";

const appDarkTheme = {
  ...MD3DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
  },
};

export default appDarkTheme;
