export function formatDateToCustomFormat(isoDate: string | number | Date) {
  const date = new Date(isoDate);

  const options = {
    weekday: "long" as const,
    day: "numeric" as const,
    month: "short" as const,
  };
  const formattedDate = date.toLocaleDateString("en-AU", options);

  return formattedDate;
}

export function formatDateWithClientTimeZone(inputDate: string): string {
  // Parse the input date string into a Date object
  const parsedDate = new Date(inputDate);
  const yyyy = parsedDate.getFullYear();
  let mm = parsedDate.getMonth() + 1; // month is zero-based
  let dd = parsedDate.getDate();
  const ddString = dd > 9 ? dd : "0" + dd;
  const mmString = mm > 9 ? mm : "0" + mm;

  const formattedDate: string = yyyy + "-" + mmString + "-" + ddString;
  return formattedDate;
}

// Example usage
// const inputDate: string = "2023-09-06T14:00:00+00:00";
// const formattedDate: string = formatDateWithClientTimeZone(inputDate);
// console.log(formattedDate); // Output: "2023-09-06"

export function formatFriendlyDate(isoString: string | date | null, includeWeekday: boolean = true): string {
  const currentDate = new Date();
  const inputDate = isoString ? new Date(isoString) : new Date();

  // Check if the input date is today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    return "today";
  }

  // Calculate yesterday's date
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  // Check if the input date is tomorrow
  if (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "yesterday";
  }

  // Calculate tomorrow's date
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  // Check if the input date is tomorrow
  if (
    inputDate.getDate() === tomorrow.getDate() &&
    inputDate.getMonth() === tomorrow.getMonth() &&
    inputDate.getFullYear() === tomorrow.getFullYear()
  ) {
    return "tomorrow";
  }

  if (includeWeekday) {
    // Format the input date as "Thursday, 13 Sep"
    const options = {
      weekday: "long" as const,
      day: "numeric" as const,
      month: "short" as const,
    };
    return inputDate.toLocaleDateString('en-AU', options);
  } else {
    // Format the input date as "Thursday, 13 Sep"
    const options = {
      day: "numeric" as const,
      month: "short" as const,
      year: "numeric" as const,
    };
    return inputDate.toLocaleDateString('en-AU', options);
  }
}

// Format the input date as day of week in format "SU", "M", "TU", "W", "TH", "F", "SA"
export function dateToWeekdayAbbreviation(dateString: string) {
  const daysOfWeek = ["SU", "M", "TU", "W", "TH", "F", "SA"];
  const date = new Date(dateString);
  const dayOfWeekIndex = date.getDay();

  if (dayOfWeekIndex >= 0 && dayOfWeekIndex < daysOfWeek.length) {
    return daysOfWeek[dayOfWeekIndex];
  } else {
    return "Invalid Date";
  }
}

export function getStartOfWeekForDate(date: Date) {
  const dayOfWeek = date.getDay();
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek;
}

export function isThisWeek(inputDate: Date): boolean {
  const currentDate = new Date();
  const currentWeekStart = getStartOfWeekForDate(currentDate);
  const currentWeekEnd = new Date(currentWeekStart);
  currentWeekEnd.setDate(currentWeekStart.getDate() + 7);

  return inputDate >= currentWeekStart && inputDate < currentWeekEnd;
}

export function getLocalStartOfDayForDate(date: Date | string): string {
  const utcOffset = new Date().getTimezoneOffset() * -60;
  const localStartOfDay = new Date(date);
  localStartOfDay.setHours(0, 0, 0, 0);
  localStartOfDay.setSeconds(localStartOfDay.getSeconds() + utcOffset);
  return localStartOfDay.toISOString().slice(0, -1);
}

export function isSameDayLocal(date1: Date, date2: Date): boolean {
  const localDate1 = getLocalStartOfDayForDate(date1);
  const localDate2 = getLocalStartOfDayForDate(date2);
  return localDate1 === localDate2;
}

export function formatTimeOfDay(date: Date | string | null): string {
  const localStartOfDay = date ? new Date(date) : new Date();
  const hours = localStartOfDay.getHours();
  const minutes = localStartOfDay.getMinutes();
  const timeOfDay = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  return timeOfDay;
}
