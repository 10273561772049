import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  Checkbox,
  Text,
  List,
  useTheme,
  Button,
  Divider,
  RadioButton,
} from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import { useCalendar } from "../provider/CalendarProvider";
import Loading from "./utils/Loading";
import {
  fetchActivityRecordingsByTypeAndDate,
  mergeActivityRecordingsAndWorkouts,
} from "../api/activityRecordings";
import { fetchWorkoutsByTypeAndDate } from "../api/activities";
import { set } from "lodash";
import { getActivityTypeLabel } from "../components/utils/activityUtils";
import { ActivityData, ActivityRecordingData } from "../types/activity";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import { formatDuration } from "../components/utils/time";
import { EllipsizeProp } from "react-native-paper/lib/typescript/types";
import WorkoutCheckboxItem from "../components/Activity/WorkoutCheckboxItem";
import WorkoutRadioButtonItem from "../components/Activity/WorkoutRadioButton";

type MergeWorkoutsProps = NativeStackScreenProps<
  MainStackParamList,
  "Merge Workouts"
>;

const MergeWorkouts: React.FC<MergeWorkoutsProps> = ({ navigation, route }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [plannedWorkouts, setPlannedWorkouts] = useState<ActivityData[]>([]);
  const [unplannedWorkouts, setUnplannedWorkouts] = useState<
    ActivityRecordingData[]
  >([]);
  const [checkedActivityRecordings, setCheckedActivityRecordings] = useState(
    {}
  );
  const [selectedWorkoutId, setSelectedWorkoutId] = useState<string>("");
  const { activity } = route.params
    ? (route.params as { activity: ActivityData })
    : {
        activity: {
          id: null,
          is_planned: false,
          workout_date: null,
          type: null,
          title: null,
        },
      };
  const { state, dispatch } = useCalendar();
  const [numberOfMatches, setNumberOfMatches] = useState(0);

  let matchingWorkouts: ActivityData[] = [];
  let matchingActivityRecordings: ActivityData[] = [];

  // function to fetch all activities of matching type and workout_date
  const fetchWorkouts = async () => {
    setLoading(true); // Now 'setLoading' can be used here
    matchingWorkouts = await fetchWorkoutsByTypeAndDate(
      activity.workout_date,
      activity.type
    );
    matchingActivityRecordings = await fetchActivityRecordingsByTypeAndDate(
      activity.workout_date,
      activity.type
    );
    // if activity.is_planned is true then set selectedWorkoutId to activity.id
    if (activity.is_planned) {
      setSelectedWorkoutId(activity.id ? activity.id : "");
    }

    console.log("matchingWorkouts", matchingWorkouts);
    console.log("matchingActivityRecordings", matchingActivityRecordings);
    setPlannedWorkouts(
      matchingWorkouts.filter((workout: ActivityData) => workout.is_planned)
    );
    setNumberOfMatches(
      matchingWorkouts.filter((workout: ActivityData) => workout.is_planned)
        .length + matchingActivityRecordings.length
    );
    setUnplannedWorkouts(matchingActivityRecordings);
    console.log("plannedWorkouts", plannedWorkouts);
    console.log("unplannedWorkouts", unplannedWorkouts);
    console.log();
    setLoading(false);
    // return matchingWorkouts;
  };

  const handleMerge = async () => {
    try {
      const checkedActivityRecordingIds = Object.keys(
        checkedActivityRecordings
      );

      // merge workouts
      console.log(selectedWorkoutId);
      console.log(checkedActivityRecordingIds);
      await mergeActivityRecordingsAndWorkouts(
        selectedWorkoutId ? [selectedWorkoutId] : [],
        checkedActivityRecordingIds
      );
      alert("Workouts merged successfully!");
      // set state to fetch activities again
      dispatch({
        type: "FETCH_ACTIVITIES",
      });

      navigation.goBack();
    } catch (error) {
      console.error("Error saving activity:", error);
    }
  };

  const handleCancel = () => {
    navigation.goBack();
  };

  useEffect(() => {
    fetchWorkouts();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (numberOfMatches === 1) {
    return (
      <ScreenWrapper>
        <Text>
          There are no {getActivityTypeLabel(activity.type)} workouts on{" "}
          {formatFriendlyDate(activity.workout_date)} to merge with "
          {activity.title}".
        </Text>
        <View style={styles.row}>
          <Button mode="outlined" onPress={handleCancel}>
            Cancel
          </Button>
        </View>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper withForms={true}>
      <Text>
        Select {getActivityTypeLabel(activity.type)} workouts on{" "}
        {formatFriendlyDate(activity.workout_date)} to merge.
      </Text>
      <List.Section>
        {/* map a list matchingWorkouts where is_planned is true */}
        <List.Subheader>Planned Workouts</List.Subheader>

        <RadioButton.Group
          onValueChange={(workoutId) => {
            setSelectedWorkoutId(workoutId);
          }}
          value={selectedWorkoutId}
        >
          {plannedWorkouts.map((workout: ActivityData) => (
            <RadioButton.Item
              value={workout.id}
              key={workout.id}
              label={
                workout.title +
                "\n" +
                "Duration: " +
                formatDuration(workout.total_time)
              }
            />
          ))}
        </RadioButton.Group>
      </List.Section>
      <Divider />
      <List.Section>
        <List.Subheader>Completed Activities</List.Subheader>
        {/* map a list matchingWorkouts where is_planned is false */}
        {unplannedWorkouts.map((workout: ActivityRecordingData) => (
          <WorkoutCheckboxItem
            workout={workout}
            duration={workout.moving_time}
            checked={checkedActivityRecordings[workout.id] || false}
            setChecked={(checked) => {
              setCheckedActivityRecordings({
                ...checkedActivityRecordings,
                [workout.id]: checked,
              });
            }}
          />
        ))}
      </List.Section>
      <View style={styles.row}>
        <Button mode="contained" onPress={handleMerge} style={styles.input}>
          Merge
        </Button>
        <Button mode="outlined" onPress={handleCancel} style={styles.input}>
          Cancel
        </Button>
      </View>
      <View style={styles.row}></View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
    // marginBottom: 12,
  },

  subheading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },

  row: {
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
});

export default MergeWorkouts;
