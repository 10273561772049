import React, { useState } from "react";
import { Button } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import DisconnectDialog from "./DisconnectDialog";
// import { Image } from 'expo-image';

const StravaDisconnectButton = () => {

  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);

  const handlePress = () => {
    // open the DisconnectDialog component
    showDialog();
  };

  return (
    <View style={styles.container}>
      <Button mode="outlined" onPress={handlePress}>
        Disconnect Strava
      </Button>
      <DisconnectDialog visible={visible} hideDialog={hideDialog} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default StravaDisconnectButton;
