import React from "react";
import { Checkbox } from "react-native-paper";
import { formatDuration } from "../utils/time";
import { formatTimeOfDay } from "../utils/dateUtils";
import { ActivityData } from "../../types/activity";

interface WorkoutCheckboxItemProps {
  workout: ActivityData;
  duration: number | null;
  index: string | number;
  key: number;
  checked: boolean;
  displayStartedAt?: boolean;
  setChecked: (checked: boolean) => void;
}

const WorkoutCheckboxItem: React.FC<WorkoutCheckboxItemProps> = ({
  workout,
  duration,
  index,
  checked,
  setChecked,
  displayStartedAt = true,
}) => {
  return (
    <Checkbox.Item
      key={index}
      label={
        workout.title +
        "\n" +
        (displayStartedAt
          ? "Started at: " + formatTimeOfDay(workout.started_at_local) + "\n"
          : "") +
        "Duration: " +
        formatDuration(duration)
      }
      status={checked ? "checked" : "unchecked"}
      onPress={() => {
        setChecked(!checked);
      }}
    />
  );
};

export default WorkoutCheckboxItem;
