import React, { useState } from "react";
import { Icon, Card, Avatar, MD3TypescaleKey, IconButton, Menu, Portal, Dialog, Paragraph, Button } from "react-native-paper";
import { types } from "../../constants/activityTypes";
import { getActivityStatusColor } from "../utils/activityStatusColor";
import { formatDate } from "../utils/time";
import { ICONS } from "../../constants/icons";
import { STRAVA_ACTIVITIES_BASE_URL } from "../../constants/urls"
import { deleteActivityRecording, purgeActivityRecording } from "../../api/activityRecordings";
import * as Linking from 'expo-linking';
import { Platform } from "react-native";

interface ActivityRecordingCardTitleProps {
  id: string;
  providerId: string;
  title: string | null;
  activityTypeEnum: number | null;
  isCompleted: boolean | null;
  startedAt: Date | string | null;
  titleVariant?: keyof typeof MD3TypescaleKey;
}

const ActivityRecordingCardTitle: React.FC<ActivityRecordingCardTitleProps> = ({
  id,
  providerId,
  title,
  activityTypeEnum,
  isCompleted,
  startedAt,
  titleVariant = "bodyLarge",
}) => {
  const [visible, setVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);
  
  const getTypeIcon = () => {
    const activityType = types.find((t) => t.value === activityTypeEnum);
    if (activityType) {
      return activityType.icon;
    }
    return "folder";
  };

  const getTypeLabel = () => {
    const activityType = types.find((t) => t.value === activityTypeEnum);
    if (activityType) {
      return activityType.label;
    }
    return "Unknown";
  };

  const getAvatarColor = () => {
    return getActivityStatusColor(isCompleted, startedAt);
  };

  const handleDelete = () => {
    closeMenu();
    setDeleteConfirmationVisible(true); // Show delete confirmation dialog
  };

  const handleViewOnStrava = () => {
    closeMenu();
    const viewOnStravaUrl = `${STRAVA_ACTIVITIES_BASE_URL}${providerId}`
    if(Platform.OS == 'web'){
      window.open(viewOnStravaUrl, '_blank');
   } else {
      Linking.openURL(viewOnStravaUrl);
   }
  };

  const handleUnmerge = () => {
    closeMenu();
    console.log("unmerge")
  };

  const confirmDelete = async () => {
    await purgeActivityRecording(id);
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
    console.log("cancel delete")
  };

  return (
    <Card.Title
      title={title}
      titleVariant={titleVariant}
      subtitle={
        isCompleted !== null ? getTypeLabel() : formatDate(startedAt, true)
      }
      left={(props) =>
        isCompleted !== null ? (
          <Avatar.Icon
            {...props}
            icon={getTypeIcon()}
            color="white"
            style={{
              backgroundColor: getAvatarColor(),
            }}
          />
        ) : (
          <Icon {...props} source={getTypeIcon()} />
        )
      }
      right={(props) => (
        <>
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={<IconButton {...props} icon={ICONS.MORE} onPress={openMenu} />}>
          <Menu.Item
              onPress={handleUnmerge}
              title="Unmerge"
              leadingIcon={ICONS.UNMERGE}
              />
          <Menu.Item
              onPress={handleDelete}
              title="Delete"
              leadingIcon={ICONS.DELETE}
              />
          <Menu.Item
              onPress={handleViewOnStrava}
              title="View on Strava"
              leadingIcon="open-in-new"
              titleStyle={{ fontWeight: 'bold' }}
              />              
        </Menu>
        <Portal>
            <Dialog
              visible={deleteConfirmationVisible}
              onDismiss={cancelDelete}
            >
              <Dialog.Title>Confirmation</Dialog.Title>
              <Dialog.Content>
                <Paragraph>
                  This will delete the original activity file but not the workout. Are you sure you want to delete this activity?
                </Paragraph>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={cancelDelete}>Cancel</Button>
                <Button onPress={confirmDelete}>Delete</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </>
      )}
    />
  );
};

export default ActivityRecordingCardTitle;
