// ProfileSection.tsx
import React from 'react';
import { View, StyleSheet } from "react-native";
import { List, TextInput, RadioButton } from 'react-native-paper';
import { DatePickerInput } from "react-native-paper-dates";

function ProfileForm({ profile, setProfile, disabled = false, showDateOfBirth = true }) {
  return (
    <View>

    <List.Section>
      <List.Subheader>Profile</List.Subheader>
      <TextInput
        label="First Name"
        value={profile.first_name ? profile.first_name : undefined}
        onChangeText={(text) =>
            setProfile({
                ...profile, // Copy the old fields
            first_name: text, // But override this one
          })
        }
        mode="outlined"
        disabled={disabled}
        />
      <TextInput
            label="Last Name"
            value={profile.last_name ? profile.last_name : undefined}
            onChangeText={(text) =>
                setProfile({
                    ...profile, // Copy the old fields
                    last_name: text, // But override this one
                })
            }
            style={styles.input}
            mode="outlined"
            disabled={disabled}
          />
          <TextInput
            label="Email"
            value={profile.email}
            onChangeText={(text) => setProfile({
                ...profile, // Copy the old fields
              email: text, // But override this one
            })}
            editable={false}
            disabled={true}
            mode="outlined"
            style={styles.input}
            />
          <TextInput
            label="Bio"
            value={profile.bio ? profile.bio : undefined}
            onChangeText={(text) =>
                setProfile({
                    ...profile, // Copy the old fields
                    bio: text, // But override this one
                })
            }
            style={styles.input}
            disabled={disabled}
            mode="outlined"
            />
            {
              showDateOfBirth && (
                <DatePickerInput
                  locale="en-GB"
                  label="Date of Birth"
                  value={
                      profile.date_of_birth
                      ? new Date(profile.date_of_birth)
                      : undefined
                  }
                  onChange={(d) =>
                      setProfile({
                          ...profile, // Copy the old fields
                          date_of_birth: d?.toDateString() || null, // But override this one
                      })
                  }
                  inputMode="start"
                  style={styles.input}
                  disabled={disabled}
                  mode="outlined"
                  />
              )
            }
          <List.Subheader>Sex</List.Subheader>
          <RadioButton.Group
            onValueChange={(text) =>
                setProfile({
                    ...profile, // Copy the old fields
                    sex: parseInt(text, 10), // But override this one
                })
            }
            value={profile.sex ? profile.sex.toString() : ""}
            >
            <RadioButton.Item label="Male" value="1" disabled={disabled} />
            <RadioButton.Item label="Female" value="2" disabled={disabled} />
            <RadioButton.Item label="Other/prefer not to set" value="3" disabled={disabled} />
          </RadioButton.Group>
        </List.Section>
        <List.Section>
          <List.Subheader>Account Type</List.Subheader>
          <RadioButton.Group
            onValueChange={(text) =>
                setProfile({
                    ...profile,
                    account_type: text,
              })
            }
            value={profile.account_type}
            >
            <RadioButton.Item label="Athlete" value="athlete" disabled={disabled} />
            <RadioButton.Item label="Coach" value="coach" disabled={disabled} />
          </RadioButton.Group>
        </List.Section>
        <List.Section>
          <List.Subheader>Location</List.Subheader>
          <TextInput
            label="City, Country"
            value={profile.location ? profile.location : undefined}
            onChangeText={(text) =>
                setProfile({
                    ...profile, // Copy the old fields
                    location: text, // But override this one
                })
            }
            disabled={disabled}
            mode="outlined"
            style={styles.input}
            />
    </List.Section>
    </View>
  );
}

const styles = StyleSheet.create({
    input: {
      marginVertical: 8,
    },
    buttonContainer: {
      alignItems: "center",
      marginVertical: 16,
    },
    saveButton: {
      width: 150,
    },
    container: {
      flex: 1,
      padding: 16,
    },
  });
  
  export default ProfileForm;