import { supabase } from "../../initSupabase";

// get user_id
export async function getUserIdFromSession() {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    return user_id;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
}

// get user email
export async function getUserEmailFromSession() {
  try {
    const email = (await supabase.auth.getSession()).data.session?.user.email;
    return email;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
}
