import isEmpty from "lodash/isEmpty";
import React, { useCallback } from "react";
import {
  StyleSheet,
  Alert,
  View,
  Text,
  TouchableOpacity,
  Button,
} from "react-native";
import ActivityCard from "./ActivityCard";
import { Database } from "../../types/supabase";
import { ActivityData } from "../../types/activity";

interface ItemProps {
  item: any;
  navigation: any;
}

const AgendaItem = (props: ItemProps) => {
  const { item, navigation } = props;
  if (isEmpty(item)) {
    return (
      <View style={styles.emptyItem}>
        <Text style={styles.emptyItemText}>No Events Planned Today</Text>
      </View>
    );
  }

  const handleActivityPress = (
    activity: ActivityData
  ) => {
    navigation.navigate("Activity", { activity });
  };

  return (
    <TouchableOpacity
      onPress={() => handleActivityPress(item)}
      style={styles.item}
    >
      <ActivityCard
        title={item.title}
        description={item.description}
        type={item.type}
        totalTimePlanned={item.total_time_planned}
        totalTimeCompleted={item.total_time}
        totalDistancePlanned={item.total_distance_planned}
        totalDistanceCompleted={item.total_distance}
        isCompleted={item.is_completed}
        startedAt={item.started_at}
      />
    </TouchableOpacity>
  );
};

export default React.memo(AgendaItem);

const styles = StyleSheet.create({
  item: {
    paddingVertical: 4,
    paddingRight: 10,
    flexDirection: "row",
  },
  itemHourText: {
    color: "black",
  },
  itemDurationText: {
    color: "grey",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  itemTitleText: {
    color: "black",
    marginLeft: 16,
    fontWeight: "bold",
    fontSize: 16,
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
  },
  emptyItemText: {
    color: "lightgrey",
    fontSize: 14,
  },
});
