import { supabase } from "../initSupabase";
import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  subDays,
  addDays,
} from "date-fns";
import { getPagination } from "./utils";
import { ActivityTypeValue } from "../types/activity";
import { LibraryWorkout, WorkoutLibraryData } from "../types/libraryWorkout";

interface QueryOptions {
  user_id?: string;
  type?: ActivityTypeValue;
  page?: number;
  pageSize?: number;
}

export async function fetchLibraryWorkouts({
  query: { user_id, type, page = 0, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase
      .from("workout_library")
      .select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }
    if (type) {
      supabaseQuery = supabaseQuery.eq("type", type);
    }
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("title", { ascending: true });
    // Fetch activities and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

// Create new workout
export async function createLibraryWorkout(libraryWorkout: LibraryWorkout) {
  console.log(libraryWorkout)
  // Insert new workout
  const { data, error } = await supabase
    .from("workout_library")
    .insert([
      libraryWorkout,
    ])
    .single();

  if (error) {
    throw error;
  }

  return { data, error };
}

// Update workout
export async function updateLibraryWorkout(libraryWorkout: LibraryWorkout) {
  // Update workout
  const { data, error } = await supabase
    .from("workout_library")
    .update(libraryWorkout)
    .eq("id", libraryWorkout.id)
    .single();

  if (error) {
    throw error;
  }

  return { data, error };
}

// Delete workout
export async function deleteLibraryWorkout(id: string) {
  // Delete workout
  const { data, error } = await supabase
    .from("workout_library")
    .delete()
    .eq("id", id)
    .single();

  if (error) {
    throw error;
  }

  return { data, error };
}

// Create new workout category
export async function createWorkoutLibraryCategory(title: string) {
  console.log('create library category', title)
  // Insert new workout
  const { data, error } = await supabase
    .from("workout_library_category")
    .insert([
      { title },
    ])

  if (error) {
    throw error;
  }

  return { data, error };
}

export async function fetchWorkoutLibraryCategories({
  query: { user_id, page = 0, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase
      .from("workout_library_category")
      .select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("title", { ascending: true });
    // Fetch activities and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

// fetchWorkoutLibraryCategories and fetchLibraryWorkouts then merge them into a single array
// [ {
//  id: string;
// title: string;
// type: 'category';
// items: any[];
// }]
export async function fetchWorkoutLibrary() {
  try {
    // Fetch categories
    const { data: categories, error: categoriesError } = await fetchWorkoutLibraryCategories();
    if (categoriesError) {
      throw categoriesError;
    }

    // Fetch workouts
    const { data: workouts, error: workoutsError } = await fetchLibraryWorkouts();
    if (workoutsError) {
      throw workoutsError;
    }

    // Workouts with no category
    const noCategoryWorkouts = workouts.filter((workout) => !workout.category_id);

    // Merge categories and workouts into a single array
    const mergedDataWithTypes = categories.map((category) => ({
      ...category,
      type: 'category', // Add type key for categories
      items: workouts.filter((workout) => workout.category_id === category.id).map((workout) => ({
        ...workout,
      })),
    }));
    
    // Add workouts with no category to the root array
    const workoutLibrary: WorkoutLibraryData = [
      ...mergedDataWithTypes,
      ...noCategoryWorkouts,
    ];
    // WorkoutLibrary

    return workoutLibrary;
  } catch (error) {
    console.error("Error fetching workout library:", error);
    throw error;
  }
}