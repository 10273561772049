import React, { useState } from "react";
import { FAB } from "react-native-paper";
import { StyleSheet } from "react-native";
import { ICONS } from "../../constants/icons";
import { NavigationProp } from "@react-navigation/native";

interface FabGroupProps {
  navigation: NavigationProp<any>;
  setIsCreateCategoryDialogVisible;
}


// Specify the type for the 'navigation' prop
const FabGroup: React.FC<CreateWorkoutCategoryDialogProps> = ({
  navigation,
  setIsCreateCategoryDialogVisible,
})=> {
  const [open, setOpen] = useState(false);

  const handleNewCategorySelected = () => {
    setIsCreateCategoryDialogVisible(true); // This shows the create category dialog
  };

  return (
    <FAB.Group
      visible={true} // Add the visible property
      open={open}
      icon={open ? "close" : "plus"}
      style={styles.fab}
      actions={[
        {
          icon: ICONS.NOTE,
          label: "Note",
          onPress: () => navigation.navigate("Edit Library Workout"),
        },
        {
          icon: ICONS.CATEGORY,
          label: "Category",
          onPress: () => handleNewCategorySelected(),
        },
        {
          icon: ICONS.WORKOUT_LIBRARY,
          label: "Workout",
          onPress: () => navigation.navigate("Edit Library Workout", { libraryWorkout: {} }),
        },
      ]}
      onStateChange={({ open }) => setOpen(open)}
      onPress={() => {
        if (open) {
          // FAB group is open
        } else {
          //   handleFabPress();
        }
      }}
    />
  );
};

export default FabGroup;

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },
})
