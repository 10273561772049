// utils/avatarColor.js
import { activityStatuses, types } from "../../constants/activityTypes";
import { ActivityTypeValue } from "../../types/activity";
import { hasActivityBeenMissed } from "./activityUtils";

export const getActivityStatusColor = (
  isCompleted: boolean | null,
  startedAt: string | Date | number | null
) => {
  if (isCompleted) {
    return activityStatuses.completed.color;
  } else if (hasActivityBeenMissed(isCompleted, startedAt)) {
    return activityStatuses.missed.color;
  }
  return activityStatuses.planned.color;
};

export const getActivityTypeIcon = (activityTypeEnum: ActivityTypeValue) => {
  const activityType = types.find((t) => t.value === activityTypeEnum);
  if (activityType) {
    return activityType.icon;
  }
  return "folder";
};

export const getActivityTypeLabel = (activityTypeEnum: ActivityTypeValue) => {
  const activityType = types.find((t) => t.value === activityTypeEnum);
  if (activityType) {
    return activityType.label;
  }
  return "Unknown";
};


