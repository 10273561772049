import React, { useState } from "react";
import { Chip, Divider, Menu } from "react-native-paper";
import { useCalendar } from "../../provider/CalendarProvider";
import { CalendarActionTypes, CoachViewTypes, CoachView } from "../../provider/CalendarProvider/types";
import { CoachedAthleteProfile } from "../../types/user";
import { filterAcceptedAthletes } from "../utils/coaches";
import { getUserName } from "../utils/profileService";

const CoachViewSelectorChip = () => {
  const { state, dispatch } = useCalendar();

  const [menuVisible, setMenuVisible] = useState(false);

  const openMenu = () => {
    console.log("Opening menu");
    setMenuVisible(true);
  };

  const closeMenu = () => {
    console.log("Closing menu");
    setMenuVisible(false);
  };

  // onPress, set state.coachView to CoachView.SELF if "My Training" is selected or CoachView.ATHLETE if an athlete is selected
  const setCoachView = (type: "SELF" | "ATHLETE", id?: string) => {
    dispatch({
      type: CalendarActionTypes.SET_COACH_VIEW,
      payload: { type, id },
    });
    console.log(state.coachView)
    // need to re-fetch activities every time coach view changes
    dispatch({ type: "SET_LOADING" });
    dispatch({ type: "FETCH_ACTIVITIES" });
    closeMenu();
  };

  const getSeletedAthlete = (): CoachedAthleteProfile | undefined => {
    return state.athletes.find(
      (athlete) => athlete.id === state.coachView.id
    );
  }

  console.log(state)

  return (
    <Menu
      visible={menuVisible}
      onDismiss={closeMenu}
      anchor={
        <Chip icon="account-filter" onPress={() => openMenu()}>
          {state.coachView.type === CoachViewTypes.SELF
            ? "My Training"
            : getUserName(getSeletedAthlete())}
        </Chip>
      }
    >
      <Menu.Item
        onPress={() => setCoachView("SELF")}
        title="My Training"
        leadingIcon="account"
      />
      <Divider />
      {/* map state.athletes into Menu.Items */}
      {filterAcceptedAthletes(state.athletes).length > 0 ? (
        filterAcceptedAthletes(state.athletes).map((athlete, index) => (
          <Menu.Item
            key={index}
            title={getUserName(athlete)}
            onPress={() => setCoachView(
              "ATHLETE",
              athlete.id
            )}
            leadingIcon="account"
          />
        ))
      ) : (
        <Menu.Item title="No athletes yet." />
      )}
    </Menu>
  );
};

export default CoachViewSelectorChip;
