import { CoachProfile, CoachedAthleteProfile, UserProfile } from "../../types/user";

export function getUserName(profile: UserProfile | CoachedAthleteProfile | CoachProfile | undefined) {
  if (profile?.first_name || profile?.last_name) {
    return `${profile?.first_name || ""} ${profile?.last_name || ""}`.trim();
  } else if (profile?.email) {
    return profile.email;
  } else {
    return "Unnamed User";
  }
}

export function getUserInitials(profile: UserProfile) {
  const firstNameInitial = profile?.first_name?.charAt(0) || "";
  const lastNameInitial = profile?.last_name?.charAt(0) || "";

  if (!firstNameInitial && !lastNameInitial && profile?.email) {
    return profile.email.charAt(0).toUpperCase();
  }

  return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
}

export function isStravaConnected(profile: UserProfile) {
  return profile?.strava_api_data?.connection_status === "connected";
}