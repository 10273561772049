import { getUserIdFromSession } from "../components/utils/authUtils";
import { supabase } from "../initSupabase";

// invoke strava-webhook-events edge function create action
export async function createStravaWebhookEventsSubscription() {
  try {
    const user_id = await getUserIdFromSession();
    const { data, error } = await supabase.functions.invoke(
      "strava-webhook-events",
      {
        method: "POST",
        body: {
          action: "CREATE",
          user_id: user_id,
        },
      }
    );

    console.log("data", data);
    console.log("error", error);

    return { data, error };
  } catch (error) {
    console.error("Error creating strava webhook events subscription:", error);
    throw error;
  }
}

// invoke strava-webhook-events edge function view action
export async function viewStravaWebhookEventsSubscription() {
  try {
    const user_id = await getUserIdFromSession();
    const { data, error } = await supabase.functions.invoke(
      "strava-webhook-events",
      {
        method: "POST",
        body: {
          action: "VIEW",
          user_id: user_id,
        },
      }
    );

    console.log("data", data);
    console.log("error", error);

    return { data, error };
  } catch (error) {
    console.error("Error viewing strava webhook events subscription:", error);
    throw error;
  }
}

// invoke strava-webhook-events edge function view action
export async function deleteStravaWebhookEventsSubscription() {
  try {
    const user_id = await getUserIdFromSession();
    const { data, error } = await supabase.functions.invoke(
      "strava-webhook-events",
      {
        method: "POST",
        body: {
          action: "DELETE",
          user_id: user_id,
        },
      }
    );

    console.log("data", data);
    console.log("error", error);

    return { data, error };
  } catch (error) {
    console.error("Error deleting strava webhook events subscription:", error);
    throw error;
  }
}

// invoke strava-webhook-events edge function to simulate Strava callback validation
export async function simulateStravaCallbackValidation() {
  try {
    const user_id = await getUserIdFromSession();
    const { data, error } = await supabase.functions.invoke(
      "strava-webhook-events",
      {
        method: "POST",
        body: {
          action: "SIMULATE_VALIDATION",
          user_id: user_id,
        },
      }
    );

    console.log("data", data);
    console.log("error", error);

    return { data, error };
  } catch (error) {
    console.error(
      "Error simulating strava callback validation subscription:",
      error
    );
    throw error;
  }
}
