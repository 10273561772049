import React, { useCallback } from "react";
import {
  StyleSheet,
} from "react-native";
import { List } from 'react-native-paper';
import { ActivityTypeValue } from "../../types/activity";
import WorkoutListItem from "./WorkoutListItem";


interface ItemProps {
  id: string;
  title: string;
  items: any[];
}

const WorkoutCategoryAccordionListItem = (props: ItemProps) => {
  const { id, title, items } = props;
 
  return (
    <List.Item
      title={title}
      left={props => <List.Icon {...props} icon="folder" />}
      onPress={() => console.log('pressed', title)}
    />
  );
};

export default WorkoutCategoryAccordionListItem;

const styles = StyleSheet.create({
  item: {
    paddingVertical: 4,
    paddingRight: 10,
    flexDirection: "row",
  },
});
