import React from "react";
import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";

interface CustomPickerProps {
  title: string;
  options: Array<{ label: string; value: number }>;
  selectedValue: number | null;
  onValueChange: (itemValue: number | null, itemIndex: number) => void;
}

const CustomPicker: React.FC<CustomPickerProps> = ({
  title,
  options,
  selectedValue,
  onValueChange,
}) => {
  return (
    <View
      style={{
        // flex: 1,
        // alignItems: "center",
        // justifyContent: "center",
        margin: 8,
        marginTop: 12,
        marginBottom: 12,
      }}
    >
      <Text
        variant="bodySmall"
        style={{
          paddingLeft: 12,
        }}
      >
        {title}
      </Text>
      <Picker selectedValue={selectedValue} onValueChange={onValueChange}>
        {options.map((option) => (
          <Picker.Item
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </Picker>
    </View>
  );
};

export default CustomPicker;
