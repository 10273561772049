import React from "react";
import { View, StyleSheet } from "react-native";
import {
  Card,
  Paragraph,
  Avatar,
  Text,
  ThemeProvider,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialIcons";
import { formatDuration } from "../utils/time";
import { metersToFormattedUserUnits } from "../utils/distance";
import ActivityRecordingCardTitle from "./ActivityRecordingCardTitle";
import { calculateFormatUserSpeed } from "../utils/calculatePace";
import { useTheme } from "react-native-paper";
import { Tables } from "../../types/supabase";

interface ActivityRecordingCardProps {
  activityRecording: Tables<"activity_recordings">;
}

const ActivityRecordingCard: React.FC<ActivityRecordingCardProps> = (props) => {
  const { activityRecording } = props;
  const theme = useTheme();

  const formattedDistance = metersToFormattedUserUnits(activityRecording.distance, 1, activityRecording.type);
  const formattedDuration = formatDuration(activityRecording.moving_time);
  const formattedPace =
    calculateFormatUserSpeed(activityRecording.distance, activityRecording.moving_time, activityRecording.type);
  console.log(activityRecording)
  return (
    <View style={styles.card}>
      <Card style={{ width: "100%" }}>
        <ActivityRecordingCardTitle
          id={activityRecording.id}
          providerId={activityRecording.provider_id}
          title={activityRecording.title}
          activityTypeEnum={activityRecording.type}
          isCompleted={null}
          startedAt={activityRecording.started_at_local}
        />
        <Card.Content>
          {activityRecording.description &&
            activityRecording.description.length > 0 && (
              <Paragraph numberOfLines={3}>
                {activityRecording.description}
              </Paragraph>
            )}
          <Paragraph>Provider: {activityRecording.provider}</Paragraph>
          <View style={styles.detailsContainer}>
            <View style={styles.durationContainer}>
              <Icon
                name="access-time"
                color={theme.colors.onSurface}
                size={24}
                style={styles.icon}
              />
              <Text variant="bodyLarge" style={styles.metricText}>
                {formattedDuration}
              </Text>
            </View>
            <View style={styles.durationContainer}>
              <Icon
                name="straighten"
                color={theme.colors.onSurface}
                size={24}
                style={styles.icon}
              />
              <Text variant="bodyLarge" style={styles.metricText}>
                {formattedDistance}
              </Text>
            </View>
            <View style={styles.durationContainer}>
              <Icon
                name="speed"
                color={theme.colors.onSurface}
                size={24}
                style={styles.icon}
              />
              <Text variant="bodyLarge" style={styles.metricText}>
                {formattedPace}
              </Text>
            </View>
          </View>
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  durationContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
    opacity: 0.8,
  },
  metricText: {
    opacity: 0.8,
  },
  type: {
    fontSize: 12,
  },
  heading: {
    marginBottom: 8,
    marginTop: 8,
  },
});

export default ActivityRecordingCard;
