import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useNavigation } from "@react-navigation/native";
import { useCalendar } from "../provider/CalendarProvider";
import { fetchProfile, updateProfile } from "../api/users";
import { supabase } from "../initSupabase";
import { Database } from "../types/supabase";
import { DatePickerInput } from "react-native-paper-dates";
import { UserProfile, initialUserProfile } from "../types/user";
import ProfileForm from "../components/User/ProfileForm";

export default function AccountSettings() {
  const [profile, setProfile] = useState<UserProfile>(initialUserProfile); // State to hold the users profile
  const navigation = useNavigation();
  const { state, dispatch } = useCalendar();

  const handleChangePassword = () => {
    // Logic to handle changing password
    navigation.navigate("ResetPassword");
  };

  const handleDeleteAccount = () => {
    // Logic to handle deleting account
  };

  // // Fetch user data from Supabase
  // const fetchUserData = async () => {
  //   const { data, error } = await supabase.auth.getSession();
  //   if (data.session.user.email) {
  //     setEmail(data.session.user.email);
  //   } else {
  //     setEmail("No email found");
  //   }
  // };

  const fetchData = async () => {
    try {
      dispatch({ type: "SET_LOADING" }); // Update the global state
      const data = await fetchProfile(); // Call the utility function

      if (data) {
        // If data is returned, update the global state
        dispatch({
          type: "SET_PROFILE",
          profile: data,
        });
        // and update the local state
        setProfile(data);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching profile:", error);
    } finally {
      dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  };

  const handleSaveChanges = async () => {
    try {
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;

      // Update existing profile
      const data = await updateProfile(user_id, profile);

      // Update the global state with the latest profile data
      fetchData();
      alert("Profile updated successfully!");

      navigation.goBack();
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  useEffect(() => {
    // Call the function to fetch user profile data when the component mounts
    if (state.fetchProfileRequired) {
      fetchData();
      dispatch({ type: "FETCH_PROFILE_COMPLETE" });
    }
  }, [state.fetchProfileRequired]);

  useEffect(() => {
    if (state.profile && !state.loading) {
      setProfile(state.profile);
    }
  }, [state.profile, state.loading]);

  return (
    <ScreenWrapper withForms={true}>
      <ProfileForm profile={profile} setProfile={setProfile} disabled={false} />
      <View style={styles.container}>
        <List.Section>
          <List.Subheader>Account</List.Subheader>
          <List.Item
            title="Change Password"
            onPress={handleChangePassword}
            style={styles.input}
          />
          <List.Item
            title="Delete Account"
            onPress={handleDeleteAccount}
            titleStyle={{ color: "red" }}
            style={styles.input}
          />
        </List.Section>
        <View style={styles.buttonContainer}>
          <Button
            mode="contained"
            onPress={handleSaveChanges}
            style={styles.saveButton}
          >
            Save Changes
          </Button>
        </View>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 16,
  },
  saveButton: {
    width: 150,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});
