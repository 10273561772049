import { supabase } from "../initSupabase";

// Call this function with the authorization code and user ID when you receive it from Strava
// sendStravaAuthCodeToSupabase("YOUR_AUTHORIZATION_CODE", "YOUR_USER_ID");
export const sendStravaAuthCodeToSupabase = async (
  authorization_code: string,
  scope: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Get the user ID from the current session
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;
      // Call the "sendAuthorizationCode" server function and pass data as arguments
      const { data, error } = await supabase.functions.invoke(
        "strava-callback",
        {
          body: {
            action: "authorize",
            code: authorization_code,
            scope: scope,
            user_id: user_id,
          },
        }
      );

      if (error) {
        console.error("Error sending Strava authorization code:", error);
        reject(error);
        return;
      }

      // Handle the response from the server function
      if (data?.success) {
        resolve("Success");
      } else {
        console.error("Failed to send Strava authorization code to Supabase");
        reject("Failed to send Strava authorization code to Supabase");
      }
    } catch (error) {
      console.error("Error sending Strava authorization code:", error);
      reject(error);
    }
  });
};

// Call this function to deauthorize Strava for the user
export const sendStravaDeauthRequestToSupabase = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;
      const { data, error } = await supabase.functions.invoke(
        "strava-callback",
        {
          body: {
            action: "deauthorize",
            user_id: user_id,
          },
        }
      );

      if (error) {
        console.error("Error sending Strava deauthorization request:", error);
        reject(error);
        return;
      }

      // Handle the response from the server function
      if (data?.success) {
        resolve("Success");
      } else {
        console.error(
          "Failed to send Strava deauthorization request to Supabase"
        );
        reject("Failed to send Strava deauthorization request to Supabase");
      }
    } catch (error) {
      console.error("Error sending Strava deauthorization request:", error);
      reject(error);
    }
  });
};

// sendStravaTokenRefreshRequestToSupabase
// Call this function to force a Strava token refresh for the user
export const sendStravaTokenRefreshRequestToSupabase = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;
      const { data, error } = await supabase.functions.invoke(
        "strava-callback",
        {
          body: {
            action: "refresh",
            user_id: user_id,
          },
        }
      );

      if (error) {
        console.error("Error sending Strava token refresh request:", error);
        reject(error);
        return;
      }

      // Handle the response from the server function
      if (data?.success) {
        resolve("Success");
      } else {
        console.error(
          "Failed to send Strava token refresh request to Supabase"
        );
        reject("Failed to send Strava token refresh request to Supabase");
      }
    } catch (error) {
      console.error("Error sending Strava token refresh request:", error);
      reject(error);
    }
  });
};

// sendFetchStravaActivtiesRequestToSupabase
// Call this function to fetch activties from Strava form the provided range
export const sendFetchStravaActivtiesRequestToSupabase = async (
  before: Date | null = null,
  after: Date | null = null
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;

      // Construct the body object with optional properties
      const requestBody: {
        action: string;
        user_id: string;
        before?: string;
        after?: string;
      } = {
        action: "fetch-activities",
        user_id,
      };

      // Convert Date objects to "YYYY-MM-DD" format
      const formatDate = (date: Date | null): string | undefined => {
        return date ? date.toISOString().split("T")[0] : undefined;
      };

      requestBody.before = formatDate(before);
      requestBody.after = formatDate(after);

      const { data, error } = await supabase.functions.invoke(
        "strava-callback",
        {
          body: requestBody,
        }
      );

      if (error) {
        console.error("Error sending fetch Strava activities request:", error);
        reject(error);
        return { error };
      }

      // Handle the response from the server function
      if (data?.success) {
        resolve({ data });
      } else {
        console.error(
          "Failed to fetch and import Strava activities to Supabase"
        );
        reject({
          error: "Failed to fetch and import Strava activities to Supabase",
        });
      }
    } catch (error) {
      console.error("Error sending fetch Strava activities request:", error);
      reject({ error });
    }
  });
};
