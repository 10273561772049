import { StyleSheet } from "react-native";
// import { useTheme } from "react-native-paper";

// const theme = useTheme();

export const commonStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

// export const getViewStyle = () => ({
//   flex: 1,
//   alignItems: "center",
//   justifyContent: "center",
//   backgroundColor: theme.colors.background,
// });

// export const viewStyles = [commonStyles.container, { backgroundColor: theme.colors.background }]
