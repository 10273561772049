// function to return the duration in "1h 30m" or "30m 23s" format
export function formatDuration(totalTimePlanned: number | null): string {
  if (totalTimePlanned === null) {
    return "-";
  }

  const hours = Math.floor(totalTimePlanned / 3600);
  const minutes = Math.floor((totalTimePlanned % 3600) / 60);

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${hours}h `;
  }

  if (minutes > 0) {
    formattedDuration += `${minutes}min`;
  }

  return formattedDuration.trim();
}

// function to return the duration in "hh:mm" or "mm:ss" format
export function formatDurationToTime(duration: number | null): string {
  if (duration === null) {
    return "-";
  }
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${hours.toString().padStart(2, "0")}:`;
  }

  formattedDuration += `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDuration;
}

export const formatDate = (
  date: Date | string | null,
  isCompleted: boolean = true
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  // if not isCompleted, remove time from options
  if (!isCompleted) {
    delete options.hour;
    delete options.minute;
  }

  if (date === null) {
    return "";
  }

  return new Date(date).toLocaleDateString(undefined, options);
};
