import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { List, useTheme } from "react-native-paper";
import { Text, TextInput, Button, Divider } from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import Switch from "../components/input/Switch";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import CustomPicker from "../components/input/CustomPicker";
import DurationInput from "../components/input/DurationInput";
import PaceInput from "../components/input/PaceInput";
import { supabase } from "../initSupabase";
import { types } from "../constants/activityTypes";
import DistanceInput from "../components/input/DistanceInput";
import { useCalendar } from "../provider/CalendarProvider";
import {
  userUnitsToMeters,
  metersToUserUnits,
} from "../components/utils/distance";
import { getLocalStartOfDayForDate } from "../components/utils/dateUtils";
import { get } from "lodash";
import { getCurrentViewUserId, getCurrentViewUserProfile } from "../provider/CalendarProvider/formatSchedule";

type EditActivityProps = NativeStackScreenProps<
  MainStackParamList,
  "Edit Activity"
> & { isDuplicateMode?: boolean }; // Add isDuplicateMode prop

type ActivityData = {
  id?: number;
  title?: string;
  description?: string;
  type?: number | null;
  started_at?: Date;
  total_time_planned?: number | null;
  total_distance_planned?: number | null;
  average_pace_planned?: number | null;
  total_time?: number | null;
  total_distance?: number | null;
  average_pace?: number | null;
  is_completed?: boolean;
};

const EditActivity: React.FC<EditActivityProps> = ({ navigation, route }) => {
  const { state, dispatch } = useCalendar();
  // change this in appbar to just pass activity id into route.params and get the activity data from state
  const { activity, isDuplicateMode } = route.params as {
    activity: ActivityData;
    isDuplicateMode: boolean;
  };
  // this screen handles create, update, duplicate activities and copy from workout library
  const isEditMode = !!activity.id;
  // replace activity from route.params with activity from state

  // Initialize activityData as an empty object
  let activityData: ActivityData = {};

  if (activity && activity.id) {
    // Assign values to activityData for editing an existing activity
    activityData = state.activities.find((obj) => obj.id === activity.id) || {};
  }

  if (isDuplicateMode && activity) {
    // Assign values to activityData for duplicating an activity
    activityData = activity;
  }

  const [title, setTitle] = useState("Endurance Run");
  const [description, setDescription] = useState("");
  const [selectedType, setSelectedType] = useState<number | null>(1);
  // if state.focusDate is defined then set workoutDate to state.focusDate otherwise use today's date
  const [workoutDate, setWorkoutDate] = useState(
    state.focusDate ? new Date(state.focusDate) : new Date()
  );
  const [isPlanned, setIsPlanned] = useState<boolean>(true); // Add isPlanned state
  const [totalTimePlanned, setTotalTimePlanned] = useState<number | null>(
    isEditMode || isDuplicateMode ? activityData.total_time_planned || 0 : 3600
  );
  const [totalTimeCompleted, setTotalTimeCompleted] = useState<number | null>(
    isEditMode ? activityData.total_time || null : null
  );
  const [totalDistancePlanned, setTotalDistancePlanned] = useState<
    number | null
  >(null);
  const [avgPacePlanned, setAvgPacePlanned] = useState<number | null>(null);
  const [totalDistanceCompleted, setTotalDistanceCompleted] = useState<
    number | null
  >(null);
  const [avgPaceCompleted, setAvgPaceCompleted] = useState<number | null>(null);
  const [isCompleted, setIsCompleted] = useState<boolean>(false); // Add isCompleted state
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (isEditMode) {
      setTitle(activityData.title || "Endurance Run");
      setDescription(activityData.description || "");
      setSelectedType(activityData.type || 1);
      setWorkoutDate(new Date(activityData.workout_date) || new Date());
      setIsPlanned(!!activityData.is_planned); // Set isCompleted value
      setTotalTimePlanned(activityData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        activityData.total_distance_planned
          ? metersToUserUnits(activityData.total_distance_planned)
          : null
      );
      setIsCompleted(!!activityData.is_completed); // Set isCompleted value
      setTotalTimeCompleted(activityData.total_time || null);
      // Convert meters to user units before storing in state
      setTotalDistanceCompleted(
        activityData.total_distance
          ? metersToUserUnits(activityData.total_distance)
          : null
      );
    }
    if (isDuplicateMode) {
      // only pre-populate planned activity data if we are duplicating an activity
      setTitle(activityData.title || "Endurance Run");
      setDescription(activityData.description || "");
      setSelectedType(activityData.type || 1);
      setWorkoutDate(new Date(activityData.workout_date) || new Date());
      setIsPlanned(true); // Set isPlanned to true
      setTotalTimePlanned(activityData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        activityData.total_distance_planned
          ? metersToUserUnits(activityData.total_distance_planned)
          : null
      );
    }
  }, [activityData]);

  const handleSaveActivity = async () => {
    if (!selectedType) {
      setError("Please select a type");
      return;
    }

    try {
      const user_id = getCurrentViewUserId(state) // (await supabase.auth.getSession()).data.session?.user.id;
      const utcOffset = new Date().getTimezoneOffset() * -60;
      const workoutDateStr = getLocalStartOfDayForDate(workoutDate);
      const startedAtUtc = new Date(workoutDate);
      startedAtUtc.setSeconds(startedAtUtc.getSeconds() - utcOffset);

      if (isEditMode && activityData.id) {
        // Update existing activity
        const { data, error } = await supabase
          .from("activities")
          .update({
            user_id,
            title,
            description,
            type: selectedType,
            workout_date: workoutDateStr,
            started_at_local: workoutDateStr,
            utc_offset: utcOffset,
            started_at: workoutDate.toISOString(),
            is_planned: isPlanned, // Include is_planned field
            total_time_planned: totalTimePlanned,
            total_distance_planned: userUnitsToMeters(totalDistancePlanned), // Convert user units to meters before storing in DB
            updated_at: new Date().toISOString(),
            is_completed: isCompleted, // Include is_completed field
            total_time: totalTimeCompleted,
            total_distance: userUnitsToMeters(totalDistanceCompleted), // Convert user units to meters before storing in DB
          })
          .eq("id", activityData.id)
          .single();

        if (error) {
          throw error;
        }
      } else {
        // Create new activity
        const { data, error } = await supabase
          .from("activities")
          .insert([
            {
              user_id,
              title,
              description,
              type: selectedType,
              workout_date: workoutDateStr,
              started_at_local: workoutDateStr,
              utc_offset: utcOffset,
              started_at: workoutDate.toISOString(),
              is_planned: isPlanned, // Include is_planned field
              total_time_planned: isPlanned ? totalTimePlanned : null,
              total_distance_planned: isPlanned
                ? userUnitsToMeters(totalDistancePlanned)
                : null, // Convert user units to meters before storing in DB
              is_completed: isCompleted,
              total_time: isCompleted ? totalTimeCompleted : null,
              total_distance: isCompleted
                ? userUnitsToMeters(totalDistanceCompleted)
                : null, // Convert user units to meters before storing in DB
              // average_pace: avgPaceCompleted,
            },
          ])
          .single();

        if (error) {
          throw error;
        }
      }
      alert(
        isDuplicateMode
          ? "Activity duplicated successfully!"
          : isEditMode
          ? "Activity updated successfully!"
          : "Activity saved successfully!"
      );
      // set state to fetch activities again
      dispatch({
        type: "FETCH_ACTIVITIES",
      });

      navigation.goBack();
    } catch (error) {
      console.error("Error saving activity:", error);
    }
  };

  const handleDurationChange = (totalSeconds: number, isCompleted: boolean) => {
    if (isCompleted) {
      setTotalTimeCompleted(totalSeconds);
    } else {
      setTotalTimePlanned(totalSeconds);
    }
  };

  const handleDistanceChange = (
    totalDistance: number | null,
    isCompleted: boolean
  ) => {
    if (isCompleted) {
      setTotalDistanceCompleted(totalDistance);
    } else {
      setTotalDistancePlanned(totalDistance);
    }
  };

  const handlePaceChange = (totalSeconds: number, isCompleted: boolean) => {
    if (isCompleted) {
      setAvgPaceCompleted(totalSeconds);
    } else {
      setAvgPacePlanned(totalSeconds);
    }
  };

  const handleDateChange = (date: Date) => {
    setWorkoutDate(date);
  };

  return (
    <ScreenWrapper withForms={true}>
      <TextInput
        label="Title"
        value={title}
        onChangeText={(text) => setTitle(text)}
        style={styles.input}
      />
      <DatePickerInput
        locale="en-GB"
        label="Date"
        value={workoutDate}
        onChange={(d) => handleDateChange(d)}
        inputMode="start"
        style={styles.input}
      />
      <CustomPicker
        title="Type"
        options={types}
        selectedValue={selectedType}
        onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
      />
      <TextInput
        label="Description"
        value={description}
        onChangeText={(text) => setDescription(text)}
        multiline={true}
        numberOfLines={6}
        style={{ minHeight : 100 }}
        style={styles.input}
      />
      <Divider />
      <List.Section>
        <Switch
          value={isPlanned}
          onValueChange={(value) => setIsPlanned(value)}
          label="Planned"
          switchProps={{
            color: "green", // Customize the switch color here
          }}
        />
        <DurationInput
          value={isPlanned ? totalTimePlanned : ""}
          onDurationChange={(totalSeconds) =>
            handleDurationChange(totalSeconds, false)
          }
          disabled={!isPlanned}
        />
        <DistanceInput
          value={isPlanned ? totalDistancePlanned : ""}
          onDistanceChange={(value) => handleDistanceChange(value, false)}
          disabled={!isPlanned}
        />
        <PaceInput
          defaultMinutes=""
          defaultSeconds=""
          onPaceChange={(totalSeconds: number) =>
            handlePaceChange(totalSeconds, true)
          }
          disabled={!isPlanned}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <Switch
          value={isCompleted}
          onValueChange={(value) => setIsCompleted(value)}
          label="Completed"
          switchProps={{
            color: "green", // Customize the switch color here
          }}
        />
        <DurationInput
          value={totalTimeCompleted}
          onDurationChange={(totalSeconds) =>
            handleDurationChange(totalSeconds, true)
          }
          disabled={!isCompleted}
        />
        <DistanceInput
          value={isCompleted ? totalDistanceCompleted : ""}
          onDistanceChange={(value) => handleDistanceChange(value, true)}
          editable={isCompleted}
          disabled={!isCompleted}
        />
        <PaceInput
          defaultMinutes=""
          defaultSeconds=""
          onPaceChange={(totalSeconds: number) =>
            handlePaceChange(totalSeconds, true)
          }
          editable={isCompleted}
          disabled={!isCompleted}
        />
      </List.Section>
      <View style={styles.row}>
        <Button mode="contained" onPress={handleSaveActivity}>
          {isDuplicateMode
            ? "Duplicate Activity"
            : isEditMode
            ? "Update Activity"
            : "Add Activity"}
        </Button>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
    // marginBottom: 12,
  },

  subheading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },

  row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
});

export default EditActivity;
