export function calculatePace(
  distanceM: number | null,
  durationSeconds: number | null
): number | null {
  if (
    distanceM === null ||
    durationSeconds === null ||
    distanceM <= 0 ||
    durationSeconds <= 0
  ) {
    return null; // Return null for invalid or missing inputs
  }

  const paceSecondsPerKm: number = durationSeconds / (distanceM / 1000);
  const paceMinutesPerKm: number = paceSecondsPerKm / 60;

  return paceMinutesPerKm;
}

export function calculateSwimPace(
  distanceM: number | null,
  durationSeconds: number | null
): number | null {
  if (
    distanceM === null ||
    durationSeconds === null ||
    distanceM <= 0 ||
    durationSeconds <= 0
  ) {
    return null; // Return null for invalid or missing inputs
  }

  const paceSecondsPer100m: number = durationSeconds / (distanceM / 100);
  const paceMinutesPerKm: number = paceSecondsPer100m / 60;

  return paceMinutesPerKm;
}

// convert metric pace in minutes, eg 4.5 to time string, eg "4:30"
export function formatPaceNumberToTimeString(pace: number | null): string | null {
  if (pace === null) {
    return null;
  }
  const minutes = Math.floor(pace);
  const seconds = Math.round((pace - minutes) * 60);
  return `${minutes.toString()}:${seconds.toString().padStart(2, "0")}`;
}

export function speedOrPaceLabel(activityType: number): string {
  if (activityType === 3 || activityType === 11) {
    return "Speed";
  }
  return "Pace";
}


export function formatPace(pace: number | null): string | null {
  if (pace === null) {
    return null;
  }
  const paceString = formatPaceNumberToTimeString(pace);  
  return `${paceString} /km`;
}

export function formatSwimPace(pace: number | null): string | null {
  if (pace === null) {
    return null;
  }
  const paceString = formatPaceNumberToTimeString(pace);  
  return `${paceString} /100m`;
}

export function calculateFormatPace(
  distanceM: number | null,
  durationSeconds: number | null
): string | null {
  return formatPace(calculatePace(distanceM, durationSeconds));
}

export function calculateFormatSwimPace(
  distanceM: number | null,
  durationSeconds: number | null
): string | null {
  return formatSwimPace(calculateSwimPace(distanceM, durationSeconds));
}

export const calculateFormatUserSpeed = (
  distanceInMeters: number | null,
  durationInSeconds: number | null,
  activityType?: number,
  decimalPlaces?: number,
): string => {
  // TODO: get user units from user settings
  // handle nulls
  if (distanceInMeters == null || durationInSeconds == null) {
    return "-";
  }
  // handle zero values
  if (distanceInMeters === 0 || durationInSeconds === 0) {
    return "-";
  }
  const userUnits = "METRIC";
  if (activityType === 3 || activityType === 11) {
    // for bike and multisport calculate speed per hour (km/h or miles/hr)
    // if decimalPlaces undefined, default to 1
    if (decimalPlaces == null) {
      decimalPlaces = 1;
    }
    return calculateFormatUserSpeedPerHour(distanceInMeters, durationInSeconds, userUnits, decimalPlaces);
  }
  // for swim, return pace in min/100m or min/100yd
  if (activityType === 2) {
    return calculateFormatSwimPace(distanceInMeters, durationInSeconds);
  }

  // for all other activity types, calculate pace (min/km or min/mile)
  return calculateFormatPace(distanceInMeters, durationInSeconds);
};

export const calculateFormatUserSpeedPerHour = (distanceInMeters: number | null, durationInSeconds: number | null, userUnits: string = "METRIC", decimalPlaces: number = 1): string => {
  if (userUnits === "METRIC") {
    const speed = calculateKilometersPerHour(distanceInMeters, durationInSeconds);
    if (speed == null) {
      return "-";
    }
    return `${speed.toFixed(decimalPlaces)} km/h`;
  }
  if (userUnits === "IMPERIAL") {
    const speed = calculateMilesPerHour(distanceInMeters, durationInSeconds);
    if (speed == null) {
      return "-";
    }
    return `${speed.toFixed(decimalPlaces)} mi/h`;
  }
  return "-";
}

export const calculateKilometersPerHour = (distanceInMeters: number | null, durationInSeconds: number | null): number | null => {
  if (distanceInMeters == null || durationInSeconds == null) {
    return null;
  }
  if (distanceInMeters === 0 || durationInSeconds === 0) {
    return null;
  }
  return (distanceInMeters / 1000) / (durationInSeconds / 3600);
};

export const calculateMilesPerHour = (distanceInMeters: number | null, durationInSeconds: number | null): number | null => {
  if (distanceInMeters == null || durationInSeconds == null) {
    return null;
  }
  if (distanceInMeters === 0 || durationInSeconds === 0) {
    return null;
  }
  return (metersToMiles(distanceInMeters) / (durationInSeconds / 3600));
};

export const calculateFormatUserSwimPace = (distanceInMeters: number | null, durationInSeconds: number | null, userUnits: string = "METRIC"): string => {
  if (userUnits === "METRIC") {
    const swimSpeed = calculateFormatPace(distanceInMeters / 100, durationInSeconds);
    if (speed == null) {
      return "-";
    }
    return `${speed.toFixed(decimalPlaces)} km/h`;
  }
  if (userUnits === "IMPERIAL") {
    const speed = calculateMilesPerHour(distanceInMeters, durationInSeconds);
    if (speed == null) {
      return "-";
    }
    return `${speed.toFixed(decimalPlaces)} mi/h`;
  }
  return "-";



  if (distanceInMeters == null || durationInSeconds == null) {
    return "-";
  }
  if (distanceInMeters === 0 || durationInSeconds === 0) {
    return "-";
  }


  if (userUnits === "METRIC") {
    return calculateFormatPace(distanceInMeters / 100, durationInSeconds);
  }
  if (userUnits === "IMPERIAL") {
    return calculateFormatPace(metersToYards(distanceInMeters) / 100, durationInSeconds);
  }
  return "-";
}



