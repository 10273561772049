import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Icon from "react-native-vector-icons/MaterialIcons";
import CommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "../provider/ThemeProvider";
import Home from "../screens/Home";
import Schedule from "../screens/Schedule";
import Analysis from "../screens/Analysis";
import More from "../screens/More";
import { ICONS } from "../constants/icons";
import { useRoute } from "@react-navigation/native";

const Tab = createBottomTabNavigator();

const MainTabs: React.FC = () => {
  const route = useRoute();
  const { theme } = useTheme(); // Get the current theme colors

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: theme.colors.primary, // Use the theme colors here
        tabBarInactiveTintColor: theme.colors.onSurface, // Use the theme colors here
        tabBarStyle: {
          backgroundColor: theme.colors.surface, // Use the theme colors here
        },
      }}
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={{
          tabBarIcon: ({ color, size }) => {
            return <Icon name={"home"} size={size} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="Schedule"
        component={Schedule}
        options={{
          tabBarIcon: ({ color, size }) => {
            return <Icon name={"calendar-today"} size={size} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="Insights"
        component={Analysis}
        options={{
          tabBarIcon: ({ color, size }) => {
            return <Icon name={"insights"} size={size} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="More"
        component={More}
        options={{
          tabBarIcon: ({ color, size }) => {
            return (
              <CommunityIcon name={ICONS.MORE} size={size} color={color} />
            );
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default MainTabs;
