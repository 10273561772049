import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TextInput, TextInputProps } from "react-native-paper";

// this component needs to get cleaned up
//  I had a lot of trouble getting it working and now theres a bunch of redundant functions
// the onChangeText in the TextInput doesnt seem to get triggered
//  so the updates are being done in a useEffect???

interface DurationInputProps extends TextInputProps {
  value: number | null;
  onDurationChange: (totalSeconds: number | null) => void;
}

const DurationInput: React.FC<DurationInputProps> = ({
  value,
  onDurationChange,
  ...textInputProps
}) => {
  // extract 'hours' from totalSeconds
  const convertHours = (totalSeconds: number | null): number => {
    if (totalSeconds === null) {
      return 0;
    } else {
      return Math.floor(totalSeconds / 3600);
    }
  };

  // extract 'minutes' from totalSeconds
  const convertMinutes = (totalSeconds: number | null): number => {
    if (totalSeconds === null) {
      return 0;
    } else {
      return Math.floor((totalSeconds % 3600) / 60);
    }
  };

  // extract 'seconds' from totalSeconds
  const convertSeconds = (totalSeconds: number | null): number => {
    if (totalSeconds === null) {
      return 0;
    } else {
      return totalSeconds % 60;
    }
  };

  const [hours, setHours] = useState<string>(
    value ? convertHours(value).toString() : ""
  );
  const [minutes, setMinutes] = useState<string>(
    value ? convertMinutes(value).toString() : ""
  );
  const [seconds, setSeconds] = useState<string>(
    value ? convertSeconds(value).toString() : ""
  );
  const [totalSeconds, setTotalSeconds] = useState<number | null>(value);

  const convertFromTotalSeconds = (
    totalSeconds: number | null
  ): { h: string; m: string; s: string } => {
    if (totalSeconds === null) {
      return { h: "", m: "", s: "" };
    } else {
      const h = convertHours(totalSeconds).toString(); //.padStart(2, "0");
      const m = convertMinutes(totalSeconds).toString(); //.padStart(2, "0");
      const s = convertSeconds(totalSeconds).toString(); //.padStart(2, "0");
      return { h, m, s };
    }
  };

  const convertToTotalSeconds = (
    h: string,
    m: string,
    s: string
  ): number | null => {
    if (h === "" && m === "" && s === "") {
      return null;
    } else {
      const hInt = parseInt(h) || 0;
      const mInt = parseInt(m) || 0;
      const sInt = parseInt(s) || 0;
      return hInt * 3600 + mInt * 60 + sInt;
    }
  };

  const handleHoursChange = (value: string) => {
    setHours(value);
    convertToTotalSeconds(value, minutes, seconds);
    onDurationChange(totalSeconds);
  };

  const handleMinutesChange = (value: string) => {
    setMinutes(value);
    convertToTotalSeconds(hours, value, seconds);
    onDurationChange(totalSeconds);
  };

  const handleSecondsChange = (value: string) => {
    setSeconds(value);
    convertToTotalSeconds(hours, minutes, value);
    onDurationChange(totalSeconds);
  };

  useEffect(() => {
    setTotalSeconds(convertToTotalSeconds(hours, minutes, seconds));
    onDurationChange(totalSeconds);
  }, [hours, minutes, seconds, totalSeconds]);

  return (
    <View style={styles.row}>
      <Text variant="bodyLarge">Duration</Text>
      <View style={styles.container}>
        <TextInput
          {...textInputProps}
          label="hh"
          value={hours}
          onChangeText={(value) => handleHoursChange(value)}
          keyboardType="numeric"
          style={styles.input}
        />
        <View style={styles.separator}>
          <Text variant="labelLarge">:</Text>
        </View>
        <TextInput
          {...textInputProps}
          label="mm"
          value={minutes}
          onChangeText={handleMinutesChange}
          keyboardType="numeric"
          style={styles.input}
        />
        <View style={styles.separator}>
          <Text variant="labelLarge">:</Text>
        </View>
        <TextInput
          {...textInputProps}
          label="ss"
          value={seconds}
          onChangeText={handleSecondsChange}
          keyboardType="numeric"
          style={styles.input}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    // margin: 8,
  },
  input: {
    width: 65,
  },
  separator: {
    alignSelf: "center",
    marginHorizontal: 4,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // paddingVertical: 8,
    paddingHorizontal: 16,
  },
});

export default DurationInput;
