import React, { useEffect, useState } from "react";
import { Text } from "react-native-paper";
import { SupabaseFunctionsResponse } from "../../types/api";

interface ErrorMessageTextProps {
  apiResponse: SupabaseFunctionsResponse;
}

const ErrorMessageText: React.FC<ErrorMessageTextProps> = ({ apiResponse }) => {
  const [errorMessage, setErrorMessage] = useState("No error");

  useEffect(() => {
    const fetchErrorDetails = async () => {
      if (apiResponse.error) {
        try {
          // Assuming apiData.error.context is a Response object
          const errorDetails = await apiResponse.error.context.json();
          setErrorMessage(
            JSON.stringify(apiResponse.error.name) +
              "," +
              JSON.stringify(errorDetails, null, 2)
          );
        } catch (error) {
          console.error("Failed to parse error context:", error);
          setErrorMessage("Failed to parse error details");
        }
      } else {
        setErrorMessage("No error");
      }
    };

    fetchErrorDetails();
  }, [apiResponse.error]);

  return <Text>{errorMessage}</Text>;
};

export default ErrorMessageText;
