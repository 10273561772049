import React, { useEffect, useState } from "react";
import { Alert, TouchableOpacity, View, useColorScheme } from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, Button, TextInput } from "react-native-paper";
// import { useRoute } from "@react-navigation/native";
import * as Linking from "expo-linking";
import AuthLayout, { authStyles } from "./AuthLayout";
import { useAuth } from "../../provider/AuthProvider";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "ResetPassword">) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, doResetPassword, setDoResetPassword } = useAuth();
  const [error, setError] = useState(null); // State to hold the error message

  async function resetPassword() {
    setLoading(true);
    // Add your logic to reset the password using Supabase or any other authentication service
    // Replace the following code with your own implementation

    // const route = useRoute();

    if (password === confirmPassword) {
      try {
        // Attempt to reset the password
        // Passwords match, proceed with password reset
        const { error } = await supabase.auth.updateUser({
          password: password,
        });

        if (error) {
          console.error("error", error);
          // Handle the error and set the error message state
          setError(error.message || "An error occurred.");
        } else {
          setError(null);
          alert("Password reset successfully!");
          // Clear the params from the browser URL
          setDoResetPassword(false);
          // const newUrl = Linking.createURL("/");
          // Linking.openURL(newUrl);
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        setError("An error occurred.");
      }
    } else {
      // Passwords do not match
      // Replace the following alert with your own logic
      setError("Passwords do not match.");
      alert("Passwords do not match!");
    }
    setLoading(false);
  }

  function handleCancel() {
    // Clear the params from the browser URL
    setDoResetPassword(false);
    // const newUrl = Linking.createURL("/");
    // Linking.openURL(newUrl);
  }

  return (
    <AuthLayout pageTitle="Reset Password">
      {error && <Text style={{ color: "red" }}>{error}</Text>}
      <Text>Email</Text>
      <TextInput
        style={{ marginTop: 15 }}
        placeholder="Email"
        value={user.email}
        disabled={true}
      />
      <Text>New Password</Text>
      <TextInput
        style={{ marginTop: 15 }}
        placeholder="Enter your new password"
        value={password}
        secureTextEntry
        onChangeText={(text) => setPassword(text)}
      />
      <Text style={{ marginTop: 15 }}>Confirm Password</Text>
      <TextInput
        style={{ marginTop: 15 }}
        placeholder="Confirm your new password"
        value={confirmPassword}
        secureTextEntry
        onChangeText={(text) => setConfirmPassword(text)}
      />
      <Button
        mode="contained"
        onPress={resetPassword}
        style={{ marginTop: 20 }}
        disabled={loading}
        loading={loading}
      >
        {loading ? "Loading" : "Reset Password"}
      </Button>
      <Button
        mode="text"
        onPress={handleCancel}
        style={{ marginTop: 20 }}
        disabled={loading}
        loading={loading}
      >
        {loading ? "Loading" : "Cancel"}
      </Button>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          justifyContent: "center",
        }}
      ></View>
    </AuthLayout>
  );
}
