import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Switch, Text } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useTheme } from "../provider/ThemeProvider";
import { supabase } from "../initSupabase";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ToggleSwitch from "../components/input/Switch";

type SettingsProps = NativeStackScreenProps<MainStackParamList, "Settings">;

export default function Activities({ navigation }: SettingsProps) {
  const { isDarkMode, toggleTheme } = useTheme();
  const handleToggleSwitch = () => {
    toggleTheme();
  };

  const handleMenuItemPress = (screenName: keyof MainStackParamList) => {
    navigation.navigate(screenName);
  };

  const [userEmail, setUserEmail] = useState<string>("");

  useEffect(() => {
    // Fetch user data from Supabase
    const fetchUserData = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data.session.user.email) {
        setUserEmail(data.session.user.email);
      } else {
        setUserEmail("No email found");
      }
    };

    fetchUserData();
  }, []);

  return (
    <ScreenWrapper>
      <List.Section>
        <List.Item
          title="Account Settings"
          description={userEmail}
          onPress={() => handleMenuItemPress("Account Settings")}
        />
      </List.Section>
      <List.Section>
        <List.Subheader>Appearance</List.Subheader>
        <ToggleSwitch
          label="Dark Mode"
          value={isDarkMode}
          onValueChange={handleToggleSwitch}
        />
      </List.Section>
      <List.Section>
        <List.Subheader>Settings</List.Subheader>
        <List.Item
          title="Setting field 1"
          description="OPTIONS"
          onPress={() => {
            // Handle onPress action
          }}
        />
        <List.Item
          title="Setting field 2"
          description="OPTIONS"
          onPress={() => {
            // Handle onPress action
          }}
        />
      </List.Section>
    </ScreenWrapper>
  );
}
