import React from "react";
import { List } from "react-native-paper";
import { useCalendar } from "../../provider/CalendarProvider";
import { isStravaConnected } from "../utils/profileService";

const ConnectedIcon: React.FC = () => {
  const { state } = useCalendar();
  const isConnected = isStravaConnected(state.profile);

  return (
    <List.Icon icon={isConnected ? "cloud-check" : "cloud-off-outline"} />
  );
};

export default ConnectedIcon;
