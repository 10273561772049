import React from "react";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { commonStyles } from "../styles";

const ThemedView: React.FC = ({ children }) => {
  const theme = useTheme();

  return <View style={[commonStyles.container, { backgroundColor: theme.colors.background }]}>{children}</View>;
};

export default ThemedView;
