import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Text } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { CoachData, CoachedAthleteProfile } from "../types/user";
import { fetchMyAthletes, fetchMyAthleteProfiles } from "../api/coaches";
import InviteAthleteButton from "../components/Coaches/InviteAthleteButton";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import {
  getUserInitials,
  getUserName,
} from "../components/utils/profileService";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import Loading from "./utils/Loading";

type MyAthletesProps = NativeStackScreenProps<
  MainStackParamList,
  "My Athletes"
>;

export default function MyAthletes({ navigation }: MyAthletesProps) {
  const { state, dispatch } = useCalendar();

  const handleAthletePress = (athlete: CoachedAthleteProfile) => {
    const profile = athlete;
    navigation.navigate("Athlete Details", { profile });
  };

  const filterAcceptedAthletes = () => {
    return state.athletes.filter((athlete) => athlete.status === "accepted");
  };

  const filterPendingAthletes = () => {
    return state.athletes.filter((athlete) => athlete.status === "pending");
  };

  useEffect(() => {
    // Define an async function
    const fetchAthletes = async () => {
      dispatch({ type: CalendarActionTypes.FETCH_ATHLETES }); // Start fetching athletes

      try {
        // Fetch coach data from Supabase
        const { data, error } = await fetchMyAthleteProfiles();
        if (error) {
          console.log("Error fetching coaches: ", error);
        } else {
          // update global state with fetched data
          dispatch({
            type: CalendarActionTypes.FETCH_ATHLETES_SUCCESS,
            athletes: data,
          }); // Fetching athletes successful
        }
      } catch (error) {
        dispatch({ type: CalendarActionTypes.FETCH_ATHLETES_FAILURE, error }); // Fetching athletes failed
      }
    };

    // Call the async function
    if (state.fetchAthletesRequired) {
      fetchAthletes();
    }
  }, [state.fetchAthletesRequired]);

  if (state.loading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      {
        // if state.profile.coaches.length > 0, render the coaches list
        filterAcceptedAthletes().length + filterPendingAthletes().length > 0 ? (
          <>
            <List.Section>
              <List.Subheader>My Athletes</List.Subheader>
              {filterAcceptedAthletes().length > 0 ? (
                filterAcceptedAthletes().map((athlete, index) => (
                  <List.Item
                    key={index}
                    title={getUserName(athlete)}
                    description={`${
                      athlete.email
                    }\nLast invitation sent: ${formatFriendlyDate(
                      new Date(athlete.updated_at).toString()
                    )}`}
                    left={() => (
                      <Avatar.Text
                        label={getUserInitials(athlete)}
                        size={40}
                        style={{ marginLeft: 16 }}
                      />
                    )}
                    onPress={() => {
                      // Handle onPress action
                      handleAthletePress(athlete);
                    }}
                  />
                ))
              ) : (
                <List.Item title="No athletes yet." />
              )}
            </List.Section>
            <List.Section>
              <List.Subheader>Pending Invitations</List.Subheader>
              {filterPendingAthletes().length > 0 ? (
                filterPendingAthletes().map((athlete, index) => (
                  <List.Item
                    key={index}
                    title={getUserName(athlete)}
                    description={`${
                      athlete.email
                    }\nLast invitation sent: ${formatFriendlyDate(
                      new Date(athlete.updated_at).toString()
                    )}`}
                    left={() => (
                      <Avatar.Text
                        label={getUserInitials(athlete)}
                        size={40}
                        style={{ marginLeft: 16 }}
                      />
                    )}
                    onPress={() => {
                      // Handle onPress action
                      handleAthletePress(athlete);
                    }}
                  />
                ))
              ) : (
                <List.Item title="No pending invitations." />
              )}
            </List.Section>
          </>
        ) : (
          <View style={styles.container}>
            <Text variant="bodyLarge" style={styles.emptyStateText}>
              You don't have any active athletes or pending coaching invitations
              yet.
            </Text>
            <Text style={styles.emptyStateText}>
              You must send an invitation in order to coach an athlete.
            </Text>
          </View>
        )
      }
      <InviteAthleteButton />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  emptyState: {},
  emptyStateText: {},
  container: {
    flex: 1,
    padding: 16,
  },
});
