import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import { Button, Dialog, Portal, Provider, TextInput } from 'react-native-paper';
import { createWorkoutLibraryCategory } from '../../api/workouts';

interface CreateWorkoutCategoryDialogProps {
  visible: boolean;
  hideDialog();
  setFetchRequired();
}

const CreateWorkoutCategoryDialog: React.FC<CreateWorkoutCategoryDialogProps> = ({
    visible,
    hideDialog,
    setFetchRequired,
  }) => {
    const [categoryTitle, setCategoryTitle] = useState<string>('');
       
    const handleCreateCategory = async () => {
      console.log('Creating category with title:', categoryTitle);
      const { data, error } =  await createWorkoutLibraryCategory(categoryTitle);
      setFetchRequired(true)
      if (error) {
        console.error('Failed to create category:', error);
        return;
      }
      hideDialog();
    };

    const handleCancel = () => {
        // clean up   
        hideDialog();
    };
  
    return (
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog}>
            <Dialog.Title>Add a new category</Dialog.Title>
            <Dialog.Content>
            <TextInput
        label="Title"
        value={categoryTitle}
        onChangeText={(text) => setCategoryTitle(text)}
        style={styles.input}
      />
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideDialog}>Cancel</Button>
              <Button onPress={handleCreateCategory}>Create</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
    );
  };
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    input: {
      margin: 8,
    },
  });
  
  export default CreateWorkoutCategoryDialog;