import React, { useState } from "react";
import { Button } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import StravaImportActivitiesDialog from "./ImportActivitiesDialog";
// import { Image } from 'expo-image';

const StravaImportActivitiesButton = () => {
  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => {
    setVisible(false);
  };

  const handlePress = () => {
    // open the DisconnectDialog component
    showDialog();
  };

  return (
    <View style={styles.container}>
      <Button mode="contained" onPress={handlePress}>
        Import Activities
      </Button>
      <StravaImportActivitiesDialog visible={visible} hideDialog={hideDialog} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
  },
});

export default StravaImportActivitiesButton;
