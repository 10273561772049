import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, TextInput, Button, useTheme } from "react-native-paper";
import AuthLayout, { authStyles } from "./AuthLayout";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { colors, dark } = useTheme();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  async function login() {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    const user = data.user;
    if (!error && !user) {
      setLoading(false);
      alert("Check your email for the login link!");
    }
    if (error) {
      setLoading(false);
      alert(error.message);
    }
  }
  return (
    <AuthLayout
      pageTitle="Login"
    >
      <Text>Email</Text>
      <TextInput
        style={authStyles.textInput}
        placeholder="Enter your email"
        value={email}
        autoCapitalize="none"
        autoCompleteType="on"
        autoCorrect={false}
        keyboardType="email-address"
        onChangeText={(text) => setEmail(text)}
      />

      <Text style={authStyles.inputHeading}>Password</Text>
      <TextInput
        style={authStyles.textInput}
        placeholder="Enter your password"
        value={password}
        autoCapitalize="none"
        autoCompleteType="off"
        autoCorrect={false}
        secureTextEntry={true}
        onChangeText={(text) => setPassword(text)}
      />
      <Button
        mode="contained"
        onPress={() => {
          login();
        }}
        style={authStyles.submitButton}
        disabled={loading}
        loading={loading}
      >
        Continue
      </Button>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          justifyContent: "center",
        }}
      >
        <Text variant="labelSmall">Don't have an account?</Text>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Register");
          }}
        >
          <Text
            variant="labelMedium"
            style={{
              marginLeft: 5,
            }}
          >
            Register here
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("ForgetPassword");
          }}
        >
          <Text variant="labelMedium">Forget password</Text>
        </TouchableOpacity>
      </View>
    </AuthLayout>
  );
}
