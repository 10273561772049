import React, { useContext } from "react";
import Navigation from "./src/navigation";
import { AuthProvider } from "./src/provider/AuthProvider";
import { enGB, registerTranslation } from "react-native-paper-dates";
import ThemeProvider, { useTheme } from "./src/provider/ThemeProvider";
import DumbTestScreen from "./src/screens/DumbTestScreen";
import { CalendarProvider } from "./src/provider/CalendarProvider";
import { getCalendarTheme } from "./src/provider/ThemeProvider/calendarTheme";
import config from "./config";

registerTranslation("en-GB", enGB);

export default function App() {
  const images = [
    require("./assets/images/long-logo-purple.png"),
    require("./assets/images/long-logo-white.png"),
  ];
  const { currentTheme } = useTheme();

  return (
    <ThemeProvider>
      <AuthProvider>
        <CalendarProvider>
          <Navigation theme={currentTheme} />
          {/* <DumbTestScreen /> */}
        </CalendarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
