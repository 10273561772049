import { Database, Json } from "./supabase";

export type StravaAthlete = {
  id: number;
  bio: string;
  sex: string;
  city: string;
  state: string;
  friend: null | string;
  summit: boolean;
  weight: number;
  country: null | string;
  premium: boolean;
  profile: string;
  follower: null | string;
  lastname: string;
  username: string;
  firstname: string;
  created_at: string;
  updated_at: string;
  badge_type_id: number;
  profile_medium: string;
  resource_state: number;
};


export type ConnectionStatus = "connected" | "disconnected" | "not_connected";

export type UserStravaData = {
  scope?: string[];
  athlete?: StravaAthlete;
  expires_at?: number;
  access_token?: string;
  refresh_token?: string;
  connection_status: ConnectionStatus;
};

type UserProfileBase = Database["public"]["Tables"]["profiles"]["Row"];
type AccountType = "athlete" | "coach";


// export type UserProfile = {
//   bio: string | null;
//   date_of_birth: string | null;
//   first_name: string | null;
//   id: string;
//   last_name: string | null;
//   email: string | null;
//   location: string | null;
//   preferences: Json;
//   sex: number | null;
//   strava_api_data: Partial<UserStravaData> | null;
//   account_type: AccountType;
// };


export type UserProfile = UserProfileBase & { 
  account_type: AccountType;
  strava_api_data: Partial<UserStravaData> | null;
};

export const initialUserProfile: UserProfile = {
  bio: null,
  date_of_birth: null,
  first_name: null,
  id: "",
  last_name: null,
  location: null,
  preferences: null,
  sex: null,
  strava_api_data: null,
  account_type: "athlete",
  created_at: null,
  email: null,
  updated_at: null,
};

export type CoachStatus =
  | "accepted"
  | "pending"
  | "declined"
  | "removed"
  | "blocked";

// export enum CoachStatus {
//     ACCEPTED = "accepted",
//     PENDING = "pending",
//     DECLINED = "declined",
//     REMOVED = "removed",
//     BLOCKED = "blocked"
// }

type CoachDataBase = Database["public"]["Tables"]["coaches"]["Row"];
// change the status from string to enum
export type CoachData = CoachDataBase & { status: CoachStatus };

// make this partial UserProfile
export type CoachedAthleteProfile = UserProfile & { status: CoachStatus };
export type CoachedAthletes = CoachedAthleteProfile[];

export type CoachProfile = CoachData & {
  bio: string | null,
  first_name: string | null,
  last_name: string | null,
  location: string | null,
  email: string,
  sex: number | null,
  account_type: string,
};
export type Coaches = CoachProfile[];


export type BasicUserData = {
  id: string;
  first_name: string;
  last_name: string;
};
