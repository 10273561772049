import { MD3LightTheme } from "react-native-paper";

const appLightTheme = {
  ...MD3LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    primary: "#6A1B9A",
    onPrimary: "#ffffff",
    primaryContainer: "#e4b5ff",
    onPrimaryContainer: "#2f004b",
    secondary: "#FF5722",
    onSecondary: "#ffffff",
    secondaryContainer: "#ffdbd1",
    tertiary: "#9a1b8b",
    onTertiary: "#ffffff",
    tertiaryContainer: "#ffd7f1",
    onTertiaryContainer: "#390033",
    error: "#ff5449",
    errorContainer: "#ffdad6",
    onError: "#ffffff",
    onErrorContainer: "#410002",
    background: "#fffbff",
    onBackground: "#1d1b1e",
    surface: "#fff7fc",
    onSurface: "#1d1b1e",
    surfaceVariant: "#eadfea",
    onSurfaceVariant: "#4b454d",
    outline: "#7c747e",
    outlineVariant: "#4b454d",
    shadow: "#000000",
    scrim: "#000000",
    inverseOnSurface: "#1d1b1e",
    inverseSurface: "#e8e0e5",
    inversePrimary: "#843ab4",
  },
};

export default appLightTheme;
