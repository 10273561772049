export function formatDistance(distance: number | null): string {
  if (distance === null) {
    return "-";
  }

  // Round to 2 decimal places if longer than 2
  // Otherwise, round to the nearest integer if within 0.02
  const roundedDistance =
    Math.abs(distance - Math.floor(distance)) > 0.02
      ? parseFloat(distance.toFixed(2))
      : Math.round(distance);

  // Convert back to string and append " km" to the end
  return roundedDistance.toString() + " km";
}

// Helper function to convert meters to kilometers
export const metersToKilometers = (
  distanceInMeters: number | null
): number | null => {
  if (distanceInMeters === null) {
    return null;
  }
  // Conversion factor: 1 kilometer = 1000 meters
  return distanceInMeters / 1000;
};

// Helper function to convert meters to miles
const metersToMiles = (distanceInMeters: number | null): number | null => {
  if (distanceInMeters === null) {
    return null;
  }
  // Conversion factor: 1 mile = 1609.344 meters
  return distanceInMeters / 1609.344;
};

// Helper function to convert kilometers to meters
const kilometersToMeters = (
  distanceInKilometers: number | null
): number | null => {
  if (distanceInKilometers === null) {
    return null;
  }
  // Conversion factor: 1 kilometer = 1000 meters
  return distanceInKilometers * 1000;
};

// Helper function to convert miles to meters
const milesToMeters = (distanceInMiles: number | null): number | null => {
  if (distanceInMiles === null) {
    return null;
  }
  // Conversion factor: 1 mile = 1609.344 meters
  return distanceInMiles * 1609.344;
};

// Helper function to convert meters to kilometers
export const metersToUserUnits = (
  distanceInMeters: number | null
): number | null => {
  // TODO: get user units from user settings
  const userUnits = "METRIC";
  // convert to kilometers
  if (userUnits === "METRIC") {
    return metersToKilometers(distanceInMeters);
  }
  if (userUnits === "IMPERIAL") {
    return metersToMiles(distanceInMeters);
  }
  // assume userUnits === "METRIC"
  return metersToKilometers(distanceInMeters);
};

// Helper function to convert meters to kilometers
export const metersToFormattedUserUnits = (
  distanceInMeters: number | null,
  decimalPlaces?: number,
  activityType?: number
): string => {
  // TODO: get user units from user settings
  if (distanceInMeters == null) {
    return "-";
  }
  const userUnits = "METRIC";


  // convert to kilometers
  if (userUnits === "METRIC") {
    // return yards or meters for swim
    if (activityType === 2) {
      return Math.round(distanceInMeters) + " m";
    }
    const distanceInKilometers = metersToKilometers(distanceInMeters);
    const formattedDistance = numberToFixed(distanceInKilometers, decimalPlaces) + " km";
    return formattedDistance;
  }
  if (userUnits === "IMPERIAL") {
    // return yards or meters for swim
    if (activityType === 2) {
      return Math.round(metersToYards(distanceInMeters)) + " yd";
    }
    return metersToMiles(distanceInMeters) + " mi";
  }
  // assume userUnits === "METRIC"
  return metersToKilometers(distanceInMeters) + " km";
};

const numberToFixed = (
  number: number,
  decimalPlaces: number | null
): number => {
  if (decimalPlaces === null) {
    return number;
  }
  return parseFloat(number.toFixed(decimalPlaces));
};

// Helper function to convert kilometers to meters
export const userUnitsToMeters = (
  distanceInUserUnits: number | null
): number | null => {
  // TODO: get user units from user settings
  const userUnits = "METRIC";
  // convert to meters
  if (userUnits === "METRIC") {
    return kilometersToMeters(distanceInUserUnits);
  }
  if (userUnits === "IMPERIAL") {
    return milesToMeters(distanceInUserUnits);
  }
  // assume userUnits === "METRIC"
  return kilometersToMeters(distanceInUserUnits);
};

export function metersToYards(meters: number | null): number | null {
  if (meters === null) {
    return null;
  }
  return meters * 1.09361;
}

export function yardsToMeters(yards: number | null): number | null {
  if (yards === null) {
    return null;
  }
  return yards / 1.09361;
}