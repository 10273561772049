import React, { useEffect, useState } from "react";
import { View, Linking, StyleSheet, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, Button, List, Avatar } from "react-native-paper";
import { useTheme } from "react-native-paper";
import TouchableActivityCard from "../components/Activity/TouchableActivityCard";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import { fetchActivities, fetchActivitiesInRange } from "../api/activities";
import { fetchProfile } from "../api/users";
import { fetchMyCoachProfiles, fetchMyAthleteProfiles } from "../api/coaches";
import {
  calculateWeeklyTotalsPerActivity,
  calculateWeeklyAndDailyTotalsPerActivity,
} from "../components/utils/activityUtils";
import { hasPendingCoachRequests } from "../components/utils/coaches";
import {
  findLatestCompletedActivity,
  findNextActivity,
  getCurrentViewUserId,
  getCurrentViewUserProfile,
} from "../provider/CalendarProvider/formatSchedule";
import TotalsCard from "../components/TotalsCard";
import {
  getUserInitials,
  getUserName,
} from "../components/utils/profileService";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import ScreenWrapper from "../components/ScreenWrapper";
import PendingCoachInvitationBanner from "../components/Coaches/PendingCoachInvitationBanner";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const theme = useTheme();
  const { state, dispatch } = useCalendar();

  const [nextActivity, setNextActivity] = useState(null); // State to hold the next activity
  const [latestCompletedActivity, setLatestCompletedActivity] = useState(null); // State to hold the latest completed activity
  const [activities, setActivities] = useState(null); // State to hold all activities
  const [totals, setTotals] = useState<{} | null>(null); // State to hold the sum of all activities for this week
  const [pendingCoaches, setPendingCoaches] = useState<boolean>(
    hasPendingCoachRequests(state.coaches)
  ); // State to hold the pending coaches
  const [currentViewUserProfile, setCurrentViewUserProfile] = useState(getCurrentViewUserProfile(state)); // State to hold the current view user profile

  const fetchData = async () => {
    try {
      dispatch({ type: "SET_LOADING" }); // Update the global state
      // check global state.coachView and only fetch activities for either SELF of the selected athlete
      const user_id = getCurrentViewUserId(state); 
      const { data } = await fetchActivitiesInRange(user_id); // Call the utility function

      if (data) {
        setActivities(data); // Update the local state
        // If data is returned, update the global state
        dispatch({
          type: "SET_SCHEDULE",
          activities: data,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching activities:", error);
    } finally {
      dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  };

  const fetchProfileData = async () => {
    try {
      dispatch({ type: "SET_LOADING" }); // Update the global state
      const data = await fetchProfile(); // Call the utility function

      if (data) {
        // If data is returned, update the global state
        dispatch({
          type: "SET_PROFILE",
          profile: data,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching profile:", error);
    } finally {
      dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  };

  useEffect(() => {
    // Call the function to fetch user profile data when the component mounts
    if (state.fetchProfileRequired) {
      fetchProfileData();
      dispatch({ type: "FETCH_PROFILE_COMPLETE" });
    }
  }, [state.fetchProfileRequired]);

  useEffect(() => {
    // Define an async function
    const fetchCoaches = async () => {
      try {
        // Fetch coach data from Supabase
        const { data, error } = await fetchMyCoachProfiles();
        if (error) {
          console.log("Error fetching coaches: ", error);
        } else {
          // update global state with fetched data
          dispatch({
            type: CalendarActionTypes.FETCH_COACHES_SUCCESS,
            coaches: data,
          }); // Fetching athletes successful
          setPendingCoaches(hasPendingCoachRequests(data)); // Update the local state with the pending coaches
        }
      } catch (error) {
        console.log(error); // Fetching coaches failed
      }
    };

    // Call the async function
    fetchCoaches();
  }, [state.fetchCoachesRequired]);


  useEffect(() => {
    // Define an async function
    const fetchAthletes = async () => {
      dispatch({ type: CalendarActionTypes.FETCH_ATHLETES }); // Start fetching athletes

      try {
        // Fetch coach data from Supabase
        const { data, error } = await fetchMyAthleteProfiles();
        if (error) {
          console.log("Error fetching coaches: ", error);
        } else {
          // update global state with fetched data
          dispatch({
            type: CalendarActionTypes.FETCH_ATHLETES_SUCCESS,
            athletes: data,
          }); // Fetching athletes successful
        }
      } catch (error) {
        console.error("failed to fetchAthletes: ", error);
        dispatch({ type: CalendarActionTypes.FETCH_ATHLETES_FAILURE, error }); // Fetching athletes failed
      }
    };

    // Call the async function
    if (state.fetchAthletesRequired) {
      fetchAthletes();
    }
  }, [state.fetchAthletesRequired]);

  useEffect(() => {
    fetchData();
    dispatch({ type: "FETCH_ACTIVITIES_COMPLETE" });
    setCurrentViewUserProfile(getCurrentViewUserProfile(state));
  }, [state.fetchRequired]);

  useEffect(() => {
    // Define an async function inside the useEffect to fetch data
    const fetchDataIfProfileSet = async () => {
      // Check if state.profile is set (or a specific property if needed)
      if (state.profile && state.profile.id) { // Assuming state.profile.id must be set
        await fetchData();
        dispatch({ type: "FETCH_ACTIVITIES_COMPLETE" });
        setCurrentViewUserProfile(getCurrentViewUserProfile(state));
      }
    };  
    fetchDataIfProfileSet();
  }, [state.profile]); // Depend on state.profile

  useEffect(() => {
    // Call the function to fetch the next and most recent activity when the component mounts
    setLatestCompletedActivity(findLatestCompletedActivity(state.activities));
    setNextActivity(findNextActivity(state.activities));
    // Sum all activities for this week
    setTotals(calculateWeeklyAndDailyTotalsPerActivity(state.activities));
  }, [state.activities]);

  return (
    <ScreenWrapper>
      {pendingCoaches && <PendingCoachInvitationBanner />}
      <List.Item
        title={getUserName(currentViewUserProfile)}
        description={
          currentViewUserProfile?.location
            ? currentViewUserProfile.location
            : "No location set."
        }
        left={(props) => (
          <Avatar.Text label={getUserInitials(currentViewUserProfile)} size={40} />
        )}
      />
      <Text>{currentViewUserProfile?.bio ? currentViewUserProfile.bio : "Bio not set."}</Text>
      <View style={style.heading}></View>
      <TotalsCard totals={totals && totals.total ? totals : null} />
      <View style={style.heading}>
        <Text variant="titleSmall">Latest Activity</Text>
      </View>
      {latestCompletedActivity ? (
        <TouchableActivityCard
          activity={latestCompletedActivity}
          navigation={navigation}
        />
      ) : (
        <Text>Nothing completed.</Text>
      )}
      <View style={style.heading}>
        <Text variant="titleSmall">
          Next Planned Workout
          {nextActivity
            ? ", " + formatFriendlyDate(nextActivity.started_at)
            : ""}
        </Text>
      </View>
      {nextActivity ? (
        <View>
          <TouchableActivityCard
            activity={nextActivity}
            navigation={navigation}
          />
        </View>
      ) : (
        <Text>Nothing planned.</Text>
      )}
    </ScreenWrapper>
  );
}

const style = StyleSheet.create({
  container: {
    flex: 1,

    width: "100%",
    padding: 16,
  },
  scrollViewContainer: {
    padding: 16,
  },
  heading: {
    marginBottom: 8,
    marginTop: 8,
  },
});
