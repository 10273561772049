import React, { useEffect, useState } from "react";
import {
  View,
  Image,
  useColorScheme,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  Dimensions,
} from "react-native";
import { Text } from "react-native-paper";
import { useTheme } from "../../provider/ThemeProvider";

interface AuthLayoutProps {
  imageSource: number;
  pageTitle: string;
  showThemeSwitch?: boolean;
}
// Get the screen width
const screenWidth = Dimensions.get("window").width;

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  imageSource,
  pageTitle,
  showThemeSwitch = true,
}) => {
  // Calculate the maximum width (760 in this case)
  const maxWidth = 760;

  const [viewWidth, setViewWidth] = useState(Math.min(maxWidth, screenWidth));

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setViewWidth(Math.min(maxWidth, window.width));
      }
    );
    return () => subscription?.remove();
  });
  // const { colors, dark, setTheme } = useTheme();
  const { isDarkMode, toggleTheme, theme, dimensions } = useTheme();
  const colorScheme = useColorScheme();
  const textInputStyle = { marginTop: 10 };

  const handleToggleSwitch = () => {
    toggleTheme();
  };

  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <ScrollView
        style={{ backgroundColor: theme.colors.background }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            width: dimensions.maxViewWidth,
            alignSelf: "center",
          }}
        >
          <View style={{ alignItems: "center", paddingTop: 100 }}>
            <Image
              resizeMode="contain"
              style={{ height: 200, width: 220 }}
              source={
                isDarkMode
                  ? require("../../../assets/images/long-logo-white.png")
                  : require("../../../assets/images/long-logo-purple.png")
              }
            />
          </View>
          <View
            style={{
              flex: 3,
              paddingHorizontal: 20,
              paddingTop: 0,
              paddingBottom: 20,
              backgroundColor: theme.colors.background,
            }}
          >
            <Text
              style={{ fontSize: 20, fontWeight: "bold", marginVertical: 10 }}
            >
              {pageTitle}
            </Text>
            {children}
          </View>
          {showThemeSwitch && (
            // Dark/Light Mode
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 30,
                justifyContent: "center",
                backgroundColor: theme.colors.background,
              }}
            >
              <TouchableOpacity onPress={handleToggleSwitch}>
                <Text style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {isDarkMode ? "☀️ light theme" : "🌑 dark theme"}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export const authStyles = StyleSheet.create({
  textInput: {
    marginTop: 10,
  },
  inputHeading: {
    marginTop: 15,
  },
  submitButton: {
    marginTop: 20,
  },
});

export default AuthLayout;
