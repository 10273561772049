import { ActivityStatus, ActivityType } from "../types/activity";

export const types: ActivityType[] = [
  { label: "Run", value: 1, icon: "run", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Swim", value: 2, icon: "swim", displayDistanceUnit: "short", displaySpeedUnits: "swim_pace" },
  { label: "Ride", value: 3, icon: "bike", displayDistanceUnit: "long", displaySpeedUnits: "distance_per_hour" },
  { label: "Strength", value: 4, icon: "weight-lifter", displayDistanceUnit: "short", displaySpeedUnits: "minutes_per_distance" },
  { label: "Workout", value: 5, icon: "heart", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Walk", value: 6, icon: "walk", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Elliptical", value: 7, icon: "ski-cross-country", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Deep Water Run", value: 8, icon: "pool", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Yoga", value: 9, icon: "yoga", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Recovery", value: 10, icon: "heart", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
  { label: "Mulitsport", value: 11, icon: "dots-horizontal-circle-outline", displayDistanceUnit: "long", displaySpeedUnits: "distance_per_hour" },
  { label: "Other", value: 12, icon: "heart", displayDistanceUnit: "long", displaySpeedUnits: "minutes_per_distance"},
];

export const activityTypes: ActivityType[] = types;

// export const activityStatuses: ActivityStatus[] = [
//   { label: "Completed", value: "COMPLETED", color: "green" },
//   { label: "Planned", value: "PLANNED", color: "grey" },
//   { label: "Missed", value: "MISSED", color: "red" },
// ];

export const activityStatuses: Record<string, ActivityStatus> = {
  completed: {
    label: "Completed",
    totalKey: {
      count: "total_count",
      distance: "total_distance",
      duration: "total_time",
    },
    color: "#3dcd58",
  },
  planned: {
    label: "Planned",
    totalKey: {
      count: "total_count_planned_incomplete",
      distance: "total_distance_planned_incomplete",
      duration: "total_time_planned_incomplete",
    },
    color: "#8293a6",
  },
  missed: {
    label: "Missed",
    totalKey: {
      count: "total_count_missed",
      distance: "total_distance_missed",
      duration: "total_time_missed",
    },
    color: "#dc0a0a",
  },
};