import React from "react";
import { View, StyleSheet } from "react-native";
import {
  Card,
  Paragraph,
  Avatar,
  Text,
  ThemeProvider,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialIcons";
import { types } from "../../constants/activityTypes";
import { formatDuration } from "../utils/time";
import { formatDistance, metersToUserUnits, metersToFormattedUserUnits } from "../utils/distance";
import ActivityCardTitle from "./ActivityCardTitle";
import { calculateFormatPace, calculateFormatUserSpeed } from "../utils/calculatePace";
import { useTheme } from "react-native-paper";

interface ActivityCardProps {
  title: string | null;
  description: string;
  type: number;
  totalTimePlanned: number;
  totalTimeCompleted: number;
  totalDistanceCompleted: number;
  totalDistancePlanned: number;
  startedAt: Date;
  isCompleted: boolean; // Add isCompleted prop
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  title,
  description,
  type,
  totalTimePlanned,
  totalTimeCompleted,
  totalDistanceCompleted,
  totalDistancePlanned,
  startedAt,
  isCompleted, // Add isCompleted prop
}) => {
  const theme = useTheme();

  const formattedDuration = formatDuration(
    isCompleted ? totalTimeCompleted : totalTimePlanned
  );
  const formattedDistance = metersToFormattedUserUnits(
    isCompleted ? totalDistanceCompleted : totalDistancePlanned,
    1,
    type
  );

  const formattedPace =
    calculateFormatUserSpeed(
          isCompleted ? totalDistanceCompleted : totalDistancePlanned,
          isCompleted ? totalTimeCompleted : totalTimePlanned,
          type
    );

  return (
    <Card style={{ width: "100%" }}>
      <ActivityCardTitle
        title={title}
        activityTypeEnum={type}
        isCompleted={isCompleted}
        startedAt={startedAt}
      />
      <Card.Content>
        {description && description.length > 0 && (
          <Paragraph numberOfLines={3}>{description}</Paragraph>
        )}
        <View style={styles.detailsContainer}>
          <View style={styles.durationContainer}>
            <Icon
              name="access-time"
              color={theme.colors.onSurface}
              size={24}
              style={styles.icon}
            />
            <Text variant="bodyLarge" style={styles.metricText}>
              {formattedDuration}
            </Text>
          </View>
          <View style={styles.durationContainer}>
            <Icon
              name="straighten"
              color={theme.colors.onSurface}
              size={24}
              style={styles.icon}
            />
            <Text variant="bodyLarge" style={styles.metricText}>
              {formattedDistance}
            </Text>
          </View>
          <View style={styles.durationContainer}>
            <Icon
              name="speed"
              color={theme.colors.onSurface}
              size={24}
              style={styles.icon}
            />
            <Text variant="bodyLarge" style={styles.metricText}>
              {formattedPace}
            </Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  durationContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
    opacity: 0.8,
  },
  metricText: {
    opacity: 0.8,
  },
  type: {
    fontSize: 12,
  },
});

export default ActivityCard;
