import React, { useContext, useEffect } from "react";
import { AuthContext, useAuth } from "../provider/AuthProvider";
import { NavigationContainer, Theme } from "@react-navigation/native";
import Main from "./MainStack";
import Auth from "./AuthStack";
import Loading from "../screens/utils/Loading";
import * as Linking from "expo-linking";
import { sendStravaAuthCodeToSupabase } from "../api/strava";

interface NavigationProps {
  theme: Theme; // Add the theme prop with the Theme type
}

// supabase password reset link is hashed with a # instead of a ?
const parseHashedUrlToQuery = (url: string) => {
  let parsedUrl = url;
  if (url.includes("#")) {
    parsedUrl = url.replace("#", "?");
  }

  return parsedUrl;
};

const Navigation: React.FC<NavigationProps> = ({ theme }) => {
  // const auth = useContext(AuthContext);
  const { user, doResetPassword, setDoResetPassword, loginWithToken } =
    useAuth();
  // const user = auth.user;

  useEffect(() => {
    // This is here to handle password reset email links
    // as well as 3rd party auth callbacks
    const handleDeepLink = async (event: { url: any }) => {
      const { url } = event;
      const parsedUrl = parseHashedUrlToQuery(url);
      const { queryParams } = Linking.parse(parsedUrl);
      if (queryParams.type === "recovery") {
        console.log("type does = recovery");
        // Perform actions specific to recovery type
        setDoResetPassword(true);
        console.log("navigation index do reset password", doResetPassword);
        const { access_token, refresh_token } = queryParams;
        if (user != true) {
          loginWithToken({ access_token, refresh_token });
        }
      }
    };

    const handleDeepLinkURL = (event: { url: any }) => {
      handleDeepLink(event);
    };

    const subscription = Linking.addEventListener("url", handleDeepLinkURL);
    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <NavigationContainer theme={theme}>
      {user == null && <Loading />}
      {user == false && <Auth />}
      {user == true && <Main />}
    </NavigationContainer>
  );
};

export default Navigation;
