import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useNavigation } from "@react-navigation/native";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import { fetchProfile, updateProfile } from "../api/users";
import { supabase } from "../initSupabase";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import { Database } from "../types/supabase";
import { DatePickerInput } from "react-native-paper-dates";
import { CoachProfile, initialUserProfile } from "../types/user";
import ProfileForm from "../components/User/ProfileForm";
import RemoveAthleteDialog from "../components/Coaches/ConfirmRemove";
import AcceptCoachDialog from "../components/Coaches/ConfirmAccept";

type MyCoachProps = NativeStackScreenProps<
  MainStackParamList,
  "My Coach Details"
>;

const MyCoachDetails: React.FC<MyCoachProps> = ({ route, navigation }) => {
  const { profile } = route.params as { profile: CoachProfile };
  const { state, dispatch } = useCalendar();

  const [removeConfirmationVisible, setRemoveConfirmationVisible] =
    useState<boolean>(false);
  const [acceptConfirmationVisible, setAcceptConfirmationVisible] =
    useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [isCoached, setIsCoached] = useState<boolean>(
    profile.status === "accepted"
  );

  // create a null function for setProfile
  const setProfile = () => {};

  // const isCoached = profile.status === "accepted";

  const handleRemoveCoach = () => {
    // Logic to handle deleting account
    setRemoveConfirmationVisible(true);
  };

  const handleAcceptCoach = () => {
    // Logic to handle deleting account
    setAcceptConfirmationVisible(true);
  };

  const coachedStatusText = () => {
    if (profile.status === "pending") {
      return `Invitation pending since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else if (isCoached) {
      return `Coaching you since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else {
      return `Coached status unknown`;
    }
  };

  useEffect(() => {
    if (isRemoved) {
      dispatch({ type: CalendarActionTypes.FETCH_COACHES }); // Dispatch FETCH_COACHES when a coach is removed
      navigation.goBack();
    }
  }, [isRemoved]);

  useEffect(() => {
    if (isCoached) {
      dispatch({ type: CalendarActionTypes.FETCH_COACHES }); // Dispatch FETCH_COACHES when a coach is accepted
    }
  }, [isCoached]);

  return (
    <>
      <ScreenWrapper withForms={true}>
        <View>
          <List.Section>
            <List.Subheader>Coaching Status</List.Subheader>
            <List.Item
              title={coachedStatusText()}
              onPress={handleAcceptCoach}
              titleStyle={isCoached ? { color: "green" } : { color: "orange" }}
            />
          </List.Section>
        </View>
        <ProfileForm
          profile={profile}
          setProfile={setProfile}
          disabled={true}
          showDateOfBirth={false}
        />
        {!isCoached && (
          <View style={styles.buttonContainer}>
            <Button mode="contained" onPress={handleAcceptCoach}>
              Accept Invitation
            </Button>
          </View>
        )}
        <View style={styles.buttonContainer}>
          <Button mode="text" onPress={handleRemoveCoach} textColor="red">
            {isCoached ? "Remove Coach" : "Decline Invitation"}
          </Button>
        </View>
      </ScreenWrapper>
      <AcceptCoachDialog
        acceptConfirmationVisible={acceptConfirmationVisible}
        setAcceptConfirmationVisible={setAcceptConfirmationVisible}
        coachId={profile.coach_id}
        athleteId={profile.athlete_id}
        setIsCoached={setIsCoached}
      />
      <RemoveAthleteDialog
        removeConfirmationVisible={removeConfirmationVisible}
        setRemoveConfirmationVisible={setRemoveConfirmationVisible}
        coachId={profile.coach_id}
        athleteId={profile.athlete_id}
        setIsRemoved={setIsRemoved}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 16,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});

export default MyCoachDetails;
