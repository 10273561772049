import React, { createRef, useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import Login from "../screens/auth/Login";
import Register from "../screens/auth/Register";
import ForgetPassword from "../screens/auth/ForgetPassword";
import ResetPassword from "../screens/auth/ResetPassword";
import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import { useAuth } from "../provider/AuthProvider";

const AuthStack = createNativeStackNavigator();

const navigationRef = createRef<NavigationContainerRef<RootStackParamsList>>();

const parseSupabaseUrl = (url: string) => {
  let parsedUrl = url;
  if (url.includes("#")) {
    parsedUrl = url.replace("#", "?");
  }

  return parsedUrl;
};

const Auth = () => {
  const { user, loginWithToken, doResetPassword, setDoResetPassword } = useAuth();

  // useEffect(() => {
  //   // This is here to handle password reset email links
  //   const handleDeepLink = async (event: { url: any }) => {
  //     const { url } = event;
  //     const parsedUrl = parseSupabaseUrl(url);
  //     const { queryParams } = Linking.parse(parsedUrl);
  //     if (queryParams.type === "recovery") {
  //       console.log("type does = recovery");
  //       // Perform actions specific to recovery type
  //       setDoResetPassword(true);
  //       console.log("AuthStack do reset password", doResetPassword)
  //       const { access_token, refresh_token } = queryParams;
  //       loginWithToken({ access_token, refresh_token });

  //       navigationRef.current?.navigate("ResetPassword", {
  //         access_token,
  //         refresh_token,
  //       });
  //       // Clear the params from the browser URL
  //       // const newUrl = Linking.createURL("/reset-password");
  //       // Linking.openURL(newUrl);
  //     } //else {
  //     //   setDoResetPassword(false);
  //     // }
  //   };
  //   // here

  //   const handleDeepLinkURL = (event: { url: any }) => {
  //     handleDeepLink(event);
  //   };

  //   const subscription = Linking.addEventListener("url", handleDeepLinkURL);
  //   return () => {
  //     subscription.remove();
  //   };
  // }, []);

  return (
    <AuthStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <AuthStack.Screen name="Login" component={Login} />
      <AuthStack.Screen name="Register" component={Register} />
      <AuthStack.Screen name="ForgetPassword" component={ForgetPassword} />
      <AuthStack.Screen name="ResetPassword" component={ResetPassword} />
    </AuthStack.Navigator>
  );
};

export default Auth;
