import isEmpty from "lodash/isEmpty";
import { formatDateWithClientTimeZone } from "../../components/utils/dateUtils";
import { MarkedDates } from "../../types/calendar";
import { Database } from "../../types/supabase";
import { CoachedAthleteProfile } from "../../types/user";

export function formatSchedule(activities: any[], daysRange: number = 90) {
  const groupedItems = [];
  const groupedActivities = {};

  // Create date objects for the specified number of days ago and ahead
  const currentDate = new Date();
  const daysAgo = new Date(currentDate);
  daysAgo.setDate(currentDate.getDate() - daysRange);
  const daysAhead = new Date(currentDate);
  daysAhead.setDate(currentDate.getDate() + daysRange);

  // Create an array of dates within the specified range
  const dateRange = [];
  const currentDay = new Date(daysAgo);
  while (currentDay <= daysAhead) {
    dateRange.push(new Date(currentDay));
    currentDay.setDate(currentDay.getDate() + 1);
  }

  // Initialize groupedActivities with empty "data" arrays for each date in the range
  dateRange.forEach((date) => {
    const formattedDate = date.toISOString().substring(0, 10);
    groupedActivities[formattedDate] = [];
  });

  // Add activities to their respective date groups
  activities.forEach((activity) => {
    // const utcDate = new Date(activity.started_at);
    // const localDate = new Date(
    //   utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    // );
    // const date = localDate.toISOString().substring(0, 10);
    const date = formatDateWithClientTimeZone(activity.workout_date);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!groupedActivities[date]) {
      groupedActivities[date] = [];
    }
    groupedActivities[date].push(activity);
  });

  // Create groupedItems with formatted data
  for (const date in groupedActivities) {
    const items = groupedActivities[date];

    groupedItems.push({
      title: date,
      data: items,
    });
  }

  return groupedItems;
}

export function getMarkedDates(schedule: any[]) {
  const marked: MarkedDates = {};

  schedule.forEach((item) => {
    // NOTE: only mark dates with data
    if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
      marked[item.title] = { marked: true };
    } else {
      marked[item.title] = { disabled: true };
    }
  });
  return marked;
}

export function convertActivitiesToAgendaSchedule(
  activities: any[],
  daysRange = 90
) {
  const result = {};

  const currentDate = new Date();
  const daysAgo = new Date(currentDate);
  daysAgo.setDate(currentDate.getDate() - daysRange);
  const daysAhead = new Date(currentDate);
  daysAhead.setDate(currentDate.getDate() + daysRange);

  const dateRange = [];
  const currentDay = new Date(daysAgo);
  while (currentDay <= daysAhead) {
    const formattedDate = currentDay.toISOString().substring(0, 10);
    result[formattedDate] = [];
    dateRange.push(formattedDate);
    currentDay.setDate(currentDay.getDate() + 1);
  }
  activities.forEach((item) => {
    const date = formatDateWithClientTimeZone(item.workout_date);
    // const date = item.started_at.split('T')[0];
    const newItem = { ...item, name: item.title };

    if (result[date]) {
      result[date].push(newItem);
    }
  });

  return dateRange.reduce((obj, date) => {
    obj[date] = result[date];
    return obj;
  }, {});
}

// Search for the most recent completed activity in state.activities
export function findLatestCompletedActivity(
  activities: Activity[] | null
): Activity | null {
  try {
    if (activities === null) {
      // Return null if activities is null
      return null;
    }

    const completedActivities = activities.filter(
      (activity) => activity.is_completed
    );
    if (completedActivities.length > 0) {
      completedActivities.sort((a, b) => {
        // Sort in descending order based on the started_at property
        return (
          new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
        );
      });
      // Return the most recent completed activity
      return completedActivities[0];
    } else {
      // No completed activities found
      return null;
    }
  } catch (error) {
    console.error("Error finding latest completed activity:", error);
    throw error;
  }
}

// Search for the next uncompleted activity in state.activities
export function findNextActivity(
  activities: Activity[] | null
): Activity | null {
  try {
    if (activities === null) {
      // Return null if activities is null
      return null;
    }

    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    const nextActivity = activities.find((activity) => {
      // Check if the activity is uncompleted and starts on or after today
      return !activity.is_completed && new Date(activity.workout_date) >= today;
    });

    return nextActivity || null; // Return the next activity or null if not found
  } catch (error) {
    console.error("Error finding next activity:", error);
    throw error;
  }
}

export const getCurrentViewUserId = (state) => {
  return state.coachView.type === "SELF" ? state.profile.id : state.coachView.id;
};

export const getCurrentViewUserProfile = (state) => {
  return state.coachView.type === "SELF" ? state.profile : state.athletes.find(
    (athlete: CoachedAthleteProfile) => athlete.id === state.coachView.id
  );
}
