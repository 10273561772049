import { ActivityData } from "./activity";
import { LibraryWorkoutData } from "./libraryWorkout";

// Define route names as constants
export const ROUTES = {
  MAIN_TABS: "MainTabs",
  SETTINGS: "Settings",
  ACCOUNT_SETTINGS: "Account Settings",
  EDIT_ACTIVITY: "Edit Activity",
  MERGE_WORKOUTS: "Merge Workouts",
  MY_ATHLETES: "My Athletes",
  MY_ATHLETE_DETAILS: "My Athlete Details",
  MY_COACHES: "My Coaches",
  ACTIVITY: "Activity",
  EDIT_LIBRARY_WORKOUT: "Edit Library Workout",
  ADMIN_TOOLS: "Admin Tools" as const,
};

// // Use the constants in your navigation stack
// export type MainStackParamList = {
//   [ROUTES.MAIN_TABS]: undefined;
//   [ROUTES.SETTINGS]: undefined;
//   [ROUTES.ACCOUNT_SETTINGS]: undefined;
//   [ROUTES.EDIT_ACTIVITY]: { activity: ActivityData, isDuplicateMode: boolean };
//   [ROUTES.MERGE_WORKOUTS]: undefined;
//   [ROUTES.MY_ATHLETES]: undefined;
//   [ROUTES.MY_ATHLETE_DETAILS]: undefined;
//   [ROUTES.MY_COACHES]: undefined;
//   [ROUTES.ACTIVITY]: { activity: ActivityData };
//   [ROUTES.EDIT_LIBRARY_WORKOUT]: { libraryWorkout: LibraryWorkoutData, isDuplicateMode: boolean };
// };

export type MainStackParamList = {
  MainTabs: undefined;
  Settings: undefined;
  "Account Settings": undefined;
  "Edit Activity": { activity: ActivityData; isDuplicateMode: boolean };
  "Merge Workouts": undefined;
  "My Athletes": undefined;
  "My Athlete Details": undefined;
  "My Coaches": undefined;
  Activity: { activity: ActivityData };
  "Edit Library Workout": {
    libraryWorkout: LibraryWorkoutData;
    isDuplicateMode: boolean;
  };
  [ROUTES.ADMIN_TOOLS]: undefined;
};

export type AuthStackParamList = {
  Login: undefined;
  Register: undefined;
  ForgetPassword: undefined;
};

export type MainTabsParamList = {
  Home: undefined;
  Profile: undefined;
  About: undefined;
  Settings: undefined;
  "Connected Apps": undefined;
  "Connect with Strava": undefined;
};
